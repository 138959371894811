import { Theme } from "@emotion/react";
import { SxProps, TextField, TextFieldProps } from "@mui/material";
import { ItemInputProps } from "./ItemInput";

type TextInputPropsType = {
  inputValue: string | number;
  min?: number | null;
  max?: number | null;
  textLengthLimit?: number | null;
  hasError?: boolean;
  sx?: SxProps<Theme>;
  label?: string;
} & ItemInputProps;

const TextInput = (props: TextInputPropsType & TextFieldProps) => {
  const {
    inputValue,
    readOnly = false,
    required,
    dataType,
    isDisabled = false,
    onInputChange,
    isPartOfPendingChangesList = false,
    min,
    max,
    textLengthLimit,
    setValidationError,
    inputWidth,
    errorMessage,
    sx,
    hasError,
    label
  } = props;
  const inputType = dataType === "int" ? "number" : "text";
  const isNumberType = inputType === "number";
  const normalizedSx: SxProps<Theme> = Array.isArray(sx) ? sx : [sx];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly || !onInputChange) return;

    const inputValue = isNumberType ? Number(e.target.value) : e.target.value;

    if (
      isNumberType &&
      min !== undefined &&
      min !== null &&
      max !== undefined &&
      max !== null
    ) {
      const isWithinBounds =
        Number(inputValue) >= min && Number(inputValue) <= max;

      if (isWithinBounds) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(`Value needs to be between ${min} and ${max}`);
      }
    } else if (
      typeof inputValue === "string" &&
      textLengthLimit !== null &&
      textLengthLimit !== undefined
    ) {
      if (inputValue.length <= textLengthLimit) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(
          `Value cannot exceed ${textLengthLimit} characters.`
        );
      }
    } else {
      onInputChange(inputValue);
    }
  };
  const isRequired = !readOnly && !isDisabled ? required : false;
  return (
    <TextField
      value={inputValue}
      type={inputType}
      label={label}
      required={isRequired}
      multiline={!isNumberType}
      error={!!errorMessage || hasError}
      aria-invalid={!!errorMessage || hasError}
      aria-required={isRequired}
      aria-readonly={readOnly}
      helperText={errorMessage}
      InputProps={{
        inputProps: {
          ispartofpendingchangeslist: (
            isPartOfPendingChangesList || false
          ).toString(),
          "aria-label": "number-text-input-field"
        },
        readOnly,
        disabled: isDisabled,
        tabIndex: readOnly ? -1 : 0
      }}
      variant="outlined"
      sx={[
        {
          width: inputWidth || 230,
          height: inputType === "text" ? "auto" : 45
        },
        ...normalizedSx
      ]}
      onChange={handleInputChange}
    />
  );
};

export default TextInput;
