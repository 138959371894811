import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Collapse,
  IconButton,
  List,
  Stack,
  Icon,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import PendingChangeIcon from "@locaisolutions/icons/dist/icons20px/Send20Px";
import RemoveIcon from "@locaisolutions/icons/dist/icons24px/Remove24Px";

import {
  removeSectionForSiteCreation,
  setSelectedSiteSection
} from "./siteSlice";
import {
  getHasRequiredSiteOperationData,
  getPendingConfigurationItemsList,
  getSelectedSiteSection,
  getSiteOperationTempData
} from "../../redux/selectors/siteSelectors";
import { useAppDispatch } from "../../store";
import { mainColours } from "../../lib/colors";

export const SectionGroupingMenuTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  whiteSpace: "nowrap",
  color: theme.palette.text.primary
}));

type SiteConfigurationProps = {
  sectionData: SiteConfigurationMenuItem;
  allConfigurationsMenuTypes: SiteConfigurationMenuGroupings;
  isInNewSiteOperationMode: boolean;
  clearSelectedConfigurationData: (waitTime?: number) => void;
};

function SiteConfiguration(props: SiteConfigurationProps) {
  const { sectionData, allConfigurationsMenuTypes } = props;
  const {
    configurationId,
    children,
    name: configurationName,
    description,
    notes,
    onePerEnvironment
  } = sectionData;
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const isInNewSiteCreatingMode =
    siteOperationTempData.mode === "newSiteCreation" &&
    hasRequiredSiteOperationData;

  const isSiteSectionSelected = selectedSiteSection.id === configurationId;

  const [configurationListStatus, setConfigurationStatus] = useState<{
    isOpen: boolean;
    configurationId: string;
  }>({
    isOpen: false,
    configurationId: configurationId
  });
  const isCurrentSectionOpen =
    configurationListStatus.isOpen &&
    configurationListStatus.configurationId === configurationId;
  const isSubSectionOpen = isCurrentSectionOpen && children.length > 0;

  const toggleSection = () => {
    setConfigurationStatus((prevConfigurationStatus) => ({
      isOpen: !prevConfigurationStatus.isOpen,
      configurationId
    }));
  };
  const removeSection = () => {
    if (isSiteSectionSelected) {
      props.clearSelectedConfigurationData(100);
    }
    dispatch(removeSectionForSiteCreation(sectionData));
  };

  const getIsSectionPartOfPendingListOfChanges = useCallback(
    (configurationId: string): boolean => {
      return !!Object.values(pendingConfigurationChangesList).find(
        (pendingChangeValue) => pendingChangeValue.sectionId === configurationId
      );
    },
    [pendingConfigurationChangesList]
  );
  const searchForPendingChanges = useCallback(
    (
      configId: string,
      children: SiteConfigurationMenuItem[]
    ): { isPartOfPendingChanges: boolean; configurationId: string } => {
      const isConfigPartOfPendingChanges =
        getIsSectionPartOfPendingListOfChanges(configId);
      if (isConfigPartOfPendingChanges)
        return {
          isPartOfPendingChanges: true,
          configurationId: configId
        };

      if (children && children.length) {
        for (const child of children) {
          const result = searchForPendingChanges(
            child.configurationId,
            child.children
          );
          if (result.isPartOfPendingChanges) {
            return result;
          }
        }
      }
      return {
        isPartOfPendingChanges: false,
        configurationId: configId
      };
    },
    [getIsSectionPartOfPendingListOfChanges]
  );

  const isCurrrentSectionPartOfPendingListChanges = useMemo(() => {
    const isSectionPartOfPendingChanges = searchForPendingChanges(
      configurationId,
      children
    );
    return (
      isSectionPartOfPendingChanges.isPartOfPendingChanges &&
      ((!configurationListStatus.isOpen &&
        isSectionPartOfPendingChanges.configurationId !==
          configurationListStatus.configurationId) ||
        isSectionPartOfPendingChanges.configurationId ===
          configurationListStatus.configurationId)
    );
  }, [
    searchForPendingChanges,
    configurationId,
    children,
    configurationListStatus
  ]);

  const onClickBtn = () => {
    if (isSiteSectionSelected) return;

    dispatch(
      setSelectedSiteSection({
        configurationId,
        name: configurationName,
        description,
        notes
      })
    );
  };

  useEffect(() => {
    if (
      !!siteOperationTempData.mode &&
      hasRequiredSiteOperationData &&
      isCurrentSectionOpen
    ) {
      toggleSection();
    }
  }, [siteOperationTempData.mode, hasRequiredSiteOperationData]);

  return (
    <Stack flexDirection="column" gap={0.5} aria-label="section container">
      <Stack flexDirection="row" gap={0.2} alignItems={"center"}>
        {children.length > 0 && (
          <IconButton
            aria-label="expand child section button"
            aria-expanded={isCurrentSectionOpen}
            onClick={() => toggleSection()}
            sx={{
              width: "22px",
              height: "22px",
              py: 0.5,
              px: 0.5
            }}
          >
            {isCurrentSectionOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <ListItemButton
          onClick={() => {
            onClickBtn();
            if (!isSubSectionOpen) toggleSection();
          }}
          selected={isSiteSectionSelected}
          sx={{
            mx: 0.3,
            py: 0.5,
            pl: 1
          }}
          aria-label="section button"
          aria-selected={isSiteSectionSelected}
        >
          <ListItemText aria-label="section name" primary={configurationName} />
          {isCurrrentSectionPartOfPendingListChanges && (
            <Icon
              sx={{
                marginLeft: "0.5rem"
              }}
            >
              <PendingChangeIcon
                style={{
                  height: 22,
                  width: 22,
                  fill: mainColours.icon.success
                }}
              />
            </Icon>
          )}
        </ListItemButton>
        {isInNewSiteCreatingMode && !onePerEnvironment && (
          <IconButton
            sx={{
              py: 0.5,
              px: 0.5
            }}
            onClick={removeSection}
            aria-label="remove section button"
          >
            <RemoveIcon
              style={{
                width: "18px",
                height: "18px"
              }}
              fill={mainColours.icon.error}
            />
          </IconButton>
        )}
      </Stack>
      {children.length > 0 && (
        <Collapse
          in={isCurrentSectionOpen}
          unmountOnExit
          sx={{
            m: 0,
            p: 0,
            ...(isSubSectionOpen && {
              borderLeft: "1.5px solid",
              borderColor: (theme) => theme.palette.divider,
              borderRadius: 0,
              ml: 1.2,
              mb: 0.5,
              pl: 0.4
            })
          }}
        >
          {children.map(
            (currentChildSection: SiteConfigurationMenuItem, i, arr) => {
              const {
                configurationId,
                configurationTypeId: currentConfigurationGroupingId
              } = currentChildSection;

              const prevChildSection = i > 0 ? arr[i - 1] : null;
              const prevConfigurationGroupingId = prevChildSection
                ? prevChildSection.configurationTypeId
                : "";

              let childGroupingHeader = null;
              if (
                !prevChildSection ||
                (prevChildSection &&
                  currentConfigurationGroupingId !==
                    prevConfigurationGroupingId)
              ) {
                childGroupingHeader =
                  allConfigurationsMenuTypes?.[currentConfigurationGroupingId];
              }

              return (
                <List
                  key={`child-site-section-${configurationId}`}
                  sx={{
                    pl: 0.4
                  }}
                  aria-label="child section container"
                  subheader={
                    childGroupingHeader?.configurationTypeName && (
                      <SectionGroupingMenuTitle
                        aria-label="child section title"
                        sx={{
                          fontSize: "0.938rem",
                          ml: 1
                        }}
                      >
                        {childGroupingHeader.configurationTypeName}
                      </SectionGroupingMenuTitle>
                    )
                  }
                  disablePadding
                >
                  <SiteConfiguration
                    {...props}
                    sectionData={currentChildSection}
                  />
                </List>
              );
            }
          )}
        </Collapse>
      )}
    </Stack>
  );
}

export default SiteConfiguration;
