import { Components, Theme } from "@mui/material";

export const muiListItemText: Components<Omit<Theme, "components">> = {
  MuiListItemText: {
    defaultProps: {
      sx: {
        "& span": {
          fontSize: 14,
          fontWeight: 500,
          color: "inherit"
        }
      }
    }
  }
};
