import { clientConfigApi } from ".";
import { updateUserMessagesList } from "../../components/messagePopper/messagePopperSlice";
import {
  setCurrentlyUsedEnvironmentIds,
  setSelectedSiteSection
} from "../../components/site/siteSlice";
import { checkIfWordsArePartOfPhrase } from "../../lib/helpers";
import {
  formatGetSiteDeploymentStatusResponse,
  getFormattedConfigurationData,
  getSiteOperationConfigurationItems
} from "../../lib/siteHelpers";
import { RootState } from "../../store";

export type GetDeploymentsStatusResponse = SiteDeploymentEvent[];
export type UpdateSiteConfigurationArgs = {
  configurationId: string;
  configurationData: {
    name: string;
    description: string;
    notes: string | null;
  };
};

export const siteApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getSitesListByPartner: builder.query<
      CustomDrawerContentType[],
      { partnerId: string }
    >({
      query: (data) => {
        const { partnerId } = data;
        return {
          url: `/api/Sites?partnerId=${partnerId}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: GetSitesListResponse
      ): CustomDrawerContentType[] => {
        return response.map((siteData) => ({
          id: siteData.siteId,
          name: siteData.name,
          salesforceId: siteData.salesforceId
        }));
      },
      providesTags: ["sites"]
    }),
    getSitesListByClient: builder.query<
      GetSitesListResponse,
      { clientId: string }
    >({
      query: (data) => {
        const { clientId } = data;
        return {
          url: `/api/Sites?clientId=${clientId}`,
          method: "GET"
        };
      },
      providesTags: ["sites"]
    }),
    getSitesList: builder.query<CustomDrawerContentType[], undefined>({
      query: () => {
        return {
          url: `/api/Sites`,
          method: "GET"
        };
      },
      transformResponse: (
        response: GetSitesListResponse
      ): CustomDrawerContentType[] => {
        return response.map((siteData) => ({
          id: siteData.siteId,
          name: siteData.name,
          salesforceId: siteData.salesforceId
        }));
      },
      providesTags: ["sites"]
    }),
    getSitesListPreview: builder.query<GetSitesListPreviewResponse, undefined>({
      query: () => {
        return {
          url: `/api/Sites/SiteList`,
          method: "GET"
        };
      },
      providesTags: []
    }),
    getSiteConfigurationsList: builder.query<
      SiteConfigurationResponseType,
      { siteId: string }
    >({
      query: (data) => {
        const { siteId } = data;
        return {
          url: `/api/Configurations?site_id=${siteId}`,
          method: "GET"
        };
      },
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data: configurationsList } = await queryFulfilled;
          const defaultConfigurationType = Object.values(
            configurationsList.allConfigurationsMenuTypes
          ).find(
            (configurationType) =>
              configurationType.displayOrder === 0 ||
              checkIfWordsArePartOfPhrase(
                ["fulfillment", "center"],
                configurationType.configurationTypeName
              )
          );
          if (!defaultConfigurationType) return;

          const configurationForDefaultType =
            configurationsList.formattedConfigurationsByMainGroupings[
              defaultConfigurationType.configurationTypeId
            ].configurations?.[0];

          if (configurationForDefaultType) {
            const { configurationId, description, name, notes } =
              configurationForDefaultType;
            dispatch(
              setSelectedSiteSection({
                configurationId,
                name,
                description,
                notes
              })
            );
            dispatch(
              siteApi.util.invalidateTags([
                {
                  type: "site configuration items",
                  id: configurationId
                }
              ])
            );
          }
        } catch {
          dispatch(
            updateUserMessagesList({
              type: "error",
              message: "Something went wrong while getting sute section data."
            })
          );
        }
      },
      transformResponse: (
        response: SiteConfigurationTypeResponse
      ): SiteConfigurationResponseType =>
        getFormattedConfigurationData(response),
      providesTags: (
        result?: SiteConfigurationResponseType,
        _meta?: unknown,
        args?: { siteId: string }
      ) => {
        return result && args?.siteId
          ? [{ type: "site sections", id: args.siteId }]
          : [{ type: "site sections", id: "LIST" }];
      }
    }),
    getConfigurationItemsListByConfiguration: builder.query<
      ConfigurationItems,
      { configurationId: string }
    >({
      query: (data) => {
        const { configurationId } = data;
        return {
          url: `/api/ConfigurationItems?configuration_id=${configurationId}`,
          method: "GET"
        };
      },
      async onQueryStarted(_arg, { dispatch, queryFulfilled, getState }) {
        try {
          const currentState = getState() as RootState;
          const { data: configurationList } =
            siteApi.endpoints.getSiteConfigurationsList.select({
              siteId:
                currentState.mainCustomDrawer.selectedDrawersData.site?.id || ""
            })(currentState);
          const { data } = await queryFulfilled;
          const configurationItemsResponse =
            data as unknown as ConfigurationItems;

          const currentConfigurationType =
            configurationList?.formattedConfigurationsByMainGroupings[
              configurationItemsResponse?.configurationTypeId
            ]?.configurations?.[0];

          if (!currentConfigurationType) return;

          if (
            currentConfigurationType.displayOrder === 0 ||
            checkIfWordsArePartOfPhrase(
              ["fulfillment", "center"],
              currentConfigurationType.name
            )
          ) {
            dispatch(
              setCurrentlyUsedEnvironmentIds(
                configurationItemsResponse.currentlyUsedEnvironmentIds
              )
            );
          } else if (!currentState.site.currentlyUsedEnvironmentIds?.length) {
            dispatch(
              setCurrentlyUsedEnvironmentIds(
                configurationItemsResponse.currentlyUsedEnvironmentIds
              )
            );
          }
        } catch {
          dispatch(
            updateUserMessagesList({
              type: "error",
              message:
                "Something went wrong while getting configuration items data."
            })
          );
        }
      },
      providesTags: (result?: ConfigurationItems) =>
        result
          ? [
              {
                type: "site configuration items",
                id: result.configurationId
              }
            ]
          : [
              {
                type: "site configuration items",
                id: "LIST"
              }
            ]
    }),
    getDuplicateSiteDetails: builder.query<
      SiteConfigurationOperationResponse,
      { siteId: string; envIdsList: string[] }
    >({
      query: (data) => {
        const { siteId, envIdsList } = data;
        return {
          url: `/api/DuplicateSite`,
          body: {
            site_id: siteId,
            environment_ids: envIdsList
          },
          method: "POST"
        };
      },
      transformResponse: (
        response: GetSiteConfigurationsOperationResponse
      ): SiteConfigurationOperationResponse => {
        const siteOperationConfigurations = getFormattedConfigurationData(
          response.configurationTypes
        );
        const siteOperationConfigurationItems =
          getSiteOperationConfigurationItems(
            response.configurationTypes,
            response.requiredConfigItemIds
          );
        const {
          requiredSiteConfigurationItems,
          allSiteConfigurationsByConfigurationId
        } = siteOperationConfigurationItems;
        const formattedReponse: SiteConfigurationOperationResponse = {
          ...response,
          requiredSiteConfigurationItems: requiredSiteConfigurationItems,
          allSiteConfigurationsByConfigurationId,
          siteConfigurationsByMenuType: siteOperationConfigurations
        };

        return formattedReponse;
      }
    }),
    postDuplicateOrCreateSite: builder.query<
      SiteCreationResponse,
      SiteCreationRequestBody
    >({
      query: (data) => {
        return {
          url: `/api/Sites`,
          method: "POST",
          body: data
        };
      }
    }),
    getDeploymentsStatus: builder.query<
      SiteDeploymentsStatusResponse,
      { siteId: string }
    >({
      query: (data) => {
        const { siteId } = data;
        return {
          url: `/api/Deployments?site_id=${siteId}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: GetDeploymentsStatusResponse
      ): SiteDeploymentsStatusResponse => {
        const formattedResponse =
          formatGetSiteDeploymentStatusResponse(response);

        return formattedResponse;
      },
      providesTags: []
    }),
    editSiteDetails: builder.mutation<
      null,
      {
        editFields: EditSiteConfigurationItemsBody;
        siteId: string;
      }
    >({
      query: (data) => {
        const { editFields, siteId } = data;
        return {
          url: `/api/Sites/${siteId}`,
          method: "PUT",
          body: editFields
        };
      },
      invalidatesTags: ["sites"]
    }),
    updateSiteSectionDetails: builder.mutation<
      SiteConfigurationOperationResponse,
      UpdateSiteConfigurationArgs
    >({
      query: (data) => {
        const { configurationId, configurationData } = data;
        return {
          url: `/api/Configurations/${configurationId}`,
          method: "PUT",
          body: configurationData
        };
      }
    }),
    addNewEnvironmentToSite: builder.mutation<
      SiteConfigurationOperationResponse,
      {
        siteId: string;
        environmentData: {
          newEnvironmentId: string;
          fromEnvironmentId: string;
        };
      }
    >({
      query: ({ siteId, environmentData }) => ({
        url: `/api/Sites/${siteId}/addenvironment`,
        method: "POST",
        body: environmentData
      }),
      transformResponse: (response: {
        site: SiteGeneralInfo;
        requiredConfigItemIds: string[];
        configurationTypes: SiteConfigurationOperationType[];
      }): SiteConfigurationOperationResponse => {
        const { site } = response;
        const siteOperationConfigurations = getFormattedConfigurationData(
          response.configurationTypes
        );
        const siteOperationConfigurationItems =
          getSiteOperationConfigurationItems(
            response.configurationTypes,
            response.requiredConfigItemIds
          );

        return {
          ...site,
          ...siteOperationConfigurationItems,
          siteConfigurationsByMenuType: siteOperationConfigurations
        };
      }
    })
  }),
  overrideExisting: true
});

export const {
  useGetSitesListByPartnerQuery,
  useGetSitesListQuery,
  useGetSitesListByClientQuery,
  useLazyGetSitesListByClientQuery,
  useGetSitesListPreviewQuery,
  useLazyGetSitesListPreviewQuery,
  useGetSiteConfigurationsListQuery,
  useGetConfigurationItemsListByConfigurationQuery,
  useGetDuplicateSiteDetailsQuery,
  usePostDuplicateOrCreateSiteQuery,
  useLazyPostDuplicateOrCreateSiteQuery,
  useGetDeploymentsStatusQuery,
  useEditSiteDetailsMutation,
  useUpdateSiteSectionDetailsMutation,
  useAddNewEnvironmentToSiteMutation
} = siteApi;

export default siteApi;
