import { useState } from "react";
import SelectEnvironmentToCopyFromContent from "./SelectEnvironmentToCopyFromContent";
import { Modal, Typography } from "@mui/material";
import {
  ModalContentContainer,
  ModalHeaderContainer
} from "../duplicate/DuplicateSiteModal";
import { basicColours } from "../../lib/colors";
import RequiredConfigurationsItems from "../siteOperations/RequiredConfigurationsItems";
import { submitRequiredSiteOperationData } from "../site/siteSlice";
import { useAppDispatch } from "../../store";

type AddNewEnvironmentModalProps = {
  onClose: () => void;
  isModalOpen: boolean;
};

const AddNewEnvironmentModal = (props: AddNewEnvironmentModalProps) => {
  const { isModalOpen, onClose } = props;
  const dispatch = useAppDispatch();

  const [updatedSiteWithNewEnvironment, updateSiteWithNewEnvironment] =
    useState<SiteConfigurationOperationResponse | null>(null);

  const onNewAddedEnvironmentSuccess = (
    siteData: SiteConfigurationOperationResponse
  ) => {
    updateSiteWithNewEnvironment(siteData);
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="add-new-environment-modal-title"
      sx={{
        height: !updatedSiteWithNewEnvironment ? "500px" : "95%",
        top: !updatedSiteWithNewEnvironment ? "15%" : "0%"
      }}
      role="dialog"
    >
      <ModalContentContainer
        sx={{
          minWidth: !updatedSiteWithNewEnvironment ? 400 : 600,
          maxWidth: !updatedSiteWithNewEnvironment ? 500 : 1200,
          width: !updatedSiteWithNewEnvironment ? "20%" : "70%"
        }}
      >
        <ModalHeaderContainer sx={{ minHeight: 50 }}>
          <Typography
            id="add-new-environment-modal-title"
            variant="h5"
            textAlign="center"
            color={basicColours.mono.white}
          >
            Add new environment to site
          </Typography>
        </ModalHeaderContainer>
        {!updatedSiteWithNewEnvironment ? (
          <SelectEnvironmentToCopyFromContent
            {...props}
            key="select-environment-content"
            onNewAddedEnvironmentSuccess={onNewAddedEnvironmentSuccess}
          />
        ) : (
          <RequiredConfigurationsItems
            siteOperationData={updatedSiteWithNewEnvironment}
            handleModalClose={props.onClose}
            submitBtnName="Submit changes"
            onSubmitRequiredFieldChanges={(pendingChanges, updatedSiteData) => {
              dispatch(
                submitRequiredSiteOperationData({
                  pendingChanges,
                  addionalNewSiteInfo: updatedSiteData
                })
              );
            }}
          />
        )}
      </ModalContentContainer>
    </Modal>
  );
};
export default AddNewEnvironmentModal;
