import { subtle } from "crypto";

export type EnvironmentColors = "staging" | "qa" | "uat" | "production";
export const basicColours = {
  mono: {
    white: "#FFFFFF",
    50: "#F6F6F6",
    100: "#EEEEEE",
    200: "#E2E2E2",
    300: "#CBCBCB",
    400: "#AFAFAF",
    500: "#757575",
    600: "#545454",
    700: "#333333",
    black: "#000000"
  },
  blue: {
    50: "#EFF3FE",
    100: "#D4E2FC",
    200: "#A0BFF8",
    300: "#5B91F5",
    400: "#276FEC",
    500: "#1E54B7",
    600: "#174291",
    700: "#102C60",
    main: "#276FEC"
  },
  green: {
    50: "#E6F2ED",
    100: "#ADDEC9",
    200: "#66D19E",
    300: "#06C167",
    400: "#15844B",
    500: "#03703C",
    600: "#03582F",
    700: "#10462D",
    main: "#15844B"
  },
  red: {
    50: "#FFEFED",
    100: "#FED7D2",
    200: "#F1998E",
    300: "#E85C4A",
    400: "#E11B22",
    500: "#AB1300",
    600: "#870F00",
    700: "#5A0A00",
    main: "#E11B22"
  },
  yellow: {
    50: "#FFFAF0",
    100: "#FFF2D9",
    200: "#FFE3AC",
    300: "#FFCF70",
    400: "#FFC043",
    500: "#956F23",
    600: "#835F19",
    700: "#674D1B",
    main: "#FFC043"
  }
};

export const mainColours = {
  background: {
    primary: basicColours.mono.white,
    secondary: "#F0F0F0",
    disabled: "#EEEEEE",
    hover: "#0000000D",
    active: "#00000017",
    secondaryLight: "#f8f8f8",
    accent: {
      primary: basicColours.blue.main,
      hover: "#185BD1"
    },
    accentLight: {
      primary: basicColours.blue[50],
      hover: "#E4ECFF"
    },
    success: {
      primary: basicColours.green.main,
      hover: "#0E733F"
    },
    successLight: {
      primary: basicColours.green[50],
      hover: "#DAEDE5"
    },
    error: {
      primary: basicColours.red.main,
      hover: "#C91218"
    },
    errorLight: {
      primary: basicColours.red[50],
      hover: "#FFE5E2"
    },
    warning: {
      primary: basicColours.yellow.main,
      hover: "#F0B135"
    },
    warningLight: {
      primary: basicColours.yellow[100],
      hover: "#F8E9CE"
    }
  },
  button: {
    subtle: {
      main: "#00000014",
      hover: "#0000001C"
    }
  },
  text: {
    primary: "#000000DE",
    secondary: "#00000099",
    disabled: "#0000004D",
    inactive: "#00000066",
    inactiveHover: "#00000099",
    accent: "#2260D3",
    success: "#137643",
    error: "#D2191F",
    warning: "#84621F"
  },
  icon: {
    primary: "#000000",
    secondary: "#0000008A",
    disabled: "#0000004D",
    inactive: "#00000059",
    inactiveHover: "#00000099",
    accent: "#2260D3",
    success: "#189A57",
    error: "#F51D00",
    warning: "#BA8A2C"
  },
  border: {
    primary: "#00000033",
    secondary: "#E2E2E2",
    accent: "#2260D3",
    success: "#189A57",
    error: "#E11B22",
    warning: "#BA8A2C"
  },
  environments: {
    staging: {
      main: "#BDD3FF",
      hover: "#99bbff",
      light: "#d1e0ff"
    },
    qa: { main: "#FFBDBD", hover: "#ff9999", light: "#ffd1d1" },
    uat: { main: "#FFD3BD", hover: "#ffbb99", light: "#ffe0d1" },
    production: { main: "#66D19E", hover: "#06C167", light: "#ADDEC9" }
  }
};
