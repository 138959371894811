import {
  Stack,
  Box,
  FormHelperText,
  FormControl,
  Typography
} from "@mui/material";
import AddCircleIcon from "@locaisolutions/icons/dist/icons20px/PlusCircle20Px";
import RemoveIcon from "@locaisolutions/icons/dist/icons24px/Remove24Px";

import { ItemInputProps } from "./ItemInput";
import TextInput from "./TextInput";
import { safeStringifyConfigurationData } from "../../lib/helpers";
import AdditionalActionsMenu from "../AdditionalActionsMenu";

type CellInputProps = {
  value: CellCoord[];
} & ItemInputProps;

const CellInput = (props: CellInputProps) => {
  const {
    value: cellCoordinatesValues,
    inputWidth,
    errorMessage,
    validationRules
  } = props;

  const onCoordinateChange = (
    coordinateType: "x" | "y",
    value: string | number,
    currentPosition: number
  ) => {
    if (!props.onInputChange || typeof value !== "number") return;

    const updatedCoordinates = cellCoordinatesValues.map((cell, index) =>
      index === currentPosition ? { ...cell, [coordinateType]: value } : cell
    );
    props.onInputChange(safeStringifyConfigurationData(updatedCoordinates));
  };

  const addNewPortCell = () => {
    if (!props.onInputChange) return;

    props.onInputChange(
      safeStringifyConfigurationData([...cellCoordinatesValues, { x: 0, y: 0 }])
    );
  };

  const removePortCell = (currentPosition: number) => {
    if (!props.onInputChange) return;

    const updatedCoordinates = cellCoordinatesValues.filter(
      (_cell, index) => index !== currentPosition
    );
    props.onInputChange(safeStringifyConfigurationData(updatedCoordinates));
  };

  return (
    <Box
      gap={3}
      width={inputWidth || 230}
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows={`repeat(${cellCoordinatesValues.length}, 1fr)`}
    >
      {!cellCoordinatesValues.length && (
        <Typography
          variant="subtitle1"
          sx={{
            alignSelf: "center",
            mx: 1
          }}
        >
          No grid cells defined
        </Typography>
      )}
      {cellCoordinatesValues.map((cellCoord: CellCoord, index: number) => {
        const { x: xCoordinate, y: yCoordinate } = cellCoord;
        return (
          <FormControl
            variant="outlined"
            error={!!errorMessage}
            key={`cell-coord-${index}`}
            sx={{
              flexDirection: "column",
              gap: 0.5
            }}
            aria-label="Cell coordinates group"
          >
            <Stack flexDirection="row" gap={1}>
              {!props.readOnly && props.onInputChange && (
                <AdditionalActionsMenu
                  buttonSx={{
                    mr: 1,
                    width: 45,
                    height: "100%"
                  }}
                  isDisabled={props.isDisabled}
                  actionsList={[
                    {
                      name: "Add port cell",
                      icon: (
                        <AddCircleIcon
                          style={{
                            height: 20,
                            width: 20
                          }}
                        />
                      ),
                      onActionCb: addNewPortCell
                    },
                    {
                      name: "Remove port cell",
                      isDisabled: cellCoordinatesValues.length === 1,
                      icon: (
                        <RemoveIcon
                          style={{
                            height: 20,
                            width: 20
                          }}
                        />
                      ),
                      onActionCb: () => {
                        removePortCell(index);
                      }
                    }
                  ]}
                />
              )}
              <TextInput
                {...props}
                inputValue={xCoordinate}
                dataType="int"
                label="X"
                sx={{ height: 48, minWidth: 80 }}
                inputWidth={"auto"}
                onInputChange={(value) => {
                  onCoordinateChange("x", value as string | number, index);
                }}
                min={validationRules?.min}
                max={validationRules?.max}
                hasError={!!errorMessage}
                errorMessage={undefined}
              />
              <TextInput
                {...props}
                inputValue={yCoordinate}
                dataType="int"
                label="Y"
                sx={{ height: 48, minWidth: 80 }}
                inputWidth={"auto"}
                error={!!errorMessage}
                onInputChange={(value) => {
                  onCoordinateChange("y", value as string | number, index);
                }}
                min={validationRules?.min}
                max={validationRules?.max}
                hasError={!!errorMessage}
                errorMessage={undefined}
              />
            </Stack>
            {errorMessage && (
              <FormHelperText
                sx={{
                  textAlign: "center",
                  m: 0
                }}
              >
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        );
      })}
    </Box>
  );
};

export default CellInput;
