import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { useGetSiteConfigurationsListQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

import SiteConfiguration, {
  SectionGroupingMenuTitle
} from "./SiteConfiguration";
import Loading from "../Loading";
import { setSelectedSiteSection, SiteOperationTempDataType } from "./siteSlice";
import ErrorPanel from "../ErrorPanel";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  getHasRequiredSiteOperationData,
  getSelectedSiteSection
} from "../../redux/selectors/siteSelectors";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../store";

const MainContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  gap: "8px",
  padding: "20px 10px",
  borderRadius: "6px",
  background: theme.palette.background.default,
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "1px solid rgba(0,0,0,0.2)",
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}));

type SiteConfigurationsProps = {
  siteOperationTempData: SiteOperationTempDataType | null;
};
function SiteConfigurations(props: SiteConfigurationsProps) {
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const { siteOperationTempData } = props;
  const { siteConfigurationsByMenuType, mode, isSiteOperationPending } =
    siteOperationTempData || {};
  const { formattedConfigurationsByMainGroupings: newSiteMenuGroupings } =
    siteConfigurationsByMenuType || {};
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const isInNewSiteCreatingMode =
    mode === "newSiteCreation" && hasRequiredSiteOperationData;

  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteId = selectedDrawersData.site?.id;

  const {
    data: currentSiteConfigurations,
    isFetching,
    isError,
    refetch
  } = useGetSiteConfigurationsListQuery(
    siteId && !isInNewSiteCreatingMode
      ? {
          siteId
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
  const menuItemsData =
    isInNewSiteCreatingMode && siteConfigurationsByMenuType
      ? siteConfigurationsByMenuType
      : currentSiteConfigurations;

  const mainMenuSections =
    isInNewSiteCreatingMode && newSiteMenuGroupings
      ? Object.keys(newSiteMenuGroupings)
      : menuItemsData
        ? Object.keys(menuItemsData.formattedConfigurationsByMainGroupings)
        : [];
  const isFetchingSiteConfigurationsData = isSiteOperationPending || isFetching;

  const clearSelectedConfigurationData = useCallback(() => {
    dispatch(
      setSelectedSiteSection({
        configurationId: "",
        name: "",
        description: "",
        notes: null
      })
    );
  }, [dispatch, setSelectedSiteSection, selectedSiteSection]);

  useEffect(() => {
    return () => {
      clearSelectedConfigurationData();
    };
  }, []);

  return (
    <MainContainer data-testid="site-configurations-menu">
      {!isFetchingSiteConfigurationsData &&
        menuItemsData &&
        mainMenuSections.map((configurationTypeId: string, index: number) => {
          const sectionItem =
            menuItemsData.formattedConfigurationsByMainGroupings[
              configurationTypeId
            ];
          const sectionConfigurationItems: SiteConfigurationMenuItem[] =
            sectionItem.configurations;

          const sectionName =
            menuItemsData.allConfigurationsMenuTypes[configurationTypeId]
              .configurationTypeName || "";

          return (
            <Stack
              flexDirection="column"
              gap={0.5}
              key={`main-site-section-${configurationTypeId}`}
              aria-label="main section container"
            >
              <SectionGroupingMenuTitle
                sx={{
                  whiteSpace: "wrap"
                }}
              >
                {sectionName}
              </SectionGroupingMenuTitle>
              {sectionConfigurationItems.map((section) => {
                return (
                  <SiteConfiguration
                    key={`site-section-${section.configurationId}`}
                    clearSelectedConfigurationData={
                      clearSelectedConfigurationData
                    }
                    sectionData={section}
                    allConfigurationsMenuTypes={
                      menuItemsData.allConfigurationsMenuTypes
                    }
                    isInNewSiteOperationMode={hasRequiredSiteOperationData}
                  />
                );
              })}
              {index !== mainMenuSections.length - 1 && (
                <Divider sx={{ borderBottomWidth: 2 }} />
              )}
            </Stack>
          );
        })}
      {!mainMenuSections.length &&
        !isFetchingSiteConfigurationsData &&
        !isError && (
          <Box
            sx={{
              padding: "0.5rem",
              maxWidth: 200
            }}
          >
            <Typography fontSize={"0.875rem"}>
              No configuration groupings found.
            </Typography>
          </Box>
        )}
      {isFetchingSiteConfigurationsData && (
        <Loading numberOfRows={15} width={220} />
      )}
      {isError && (
        <ErrorPanel
          errorMessage={`Failed to retrieve ${selectedDrawersData.site?.name} site sections data.`}
          size="small"
          sx={{
            textAlign: "center",
            maxHeight: "200px"
          }}
          onRetryCb={async () => {
            await refetch();
          }}
        />
      )}
    </MainContainer>
  );
}

export default SiteConfigurations;
