import { Components, Theme } from "@mui/material";
import { mainColours } from "./colors";

export const muiSelect: Components<Omit<Theme, "components">> = {
  MuiSelect: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        height: 48,
        padding: 0,
        backgroundColor: theme.palette.background.paper,
        ...(ownerState.disabled && {
          cursor: "not-allowed",
          "& .MuiOutlinedInput-input": {
            color: theme.palette.text.disabled
          }
        }),
        ...(ownerState.error && {
          backgroundColor: theme.palette.error.light
        }),
        ...(ownerState?.inputProps?.ispartofpendingchangeslist === "true" && {
          backgroundColor: theme.palette.success.light
        }),
        "& .MuiOutlinedInput-notchedOutline": {
          top: 0
        },
        "& fieldset": {
          borderColor: mainColours.border.secondary,
          ...(ownerState.error && {
            borderWidth: "2px",
            borderColor: theme.palette.success.dark
          }),
          ...(ownerState?.inputProps?.ispartofpendingchangeslist === "true" && {
            borderWidth: "2px",
            borderColor: theme.palette.success.dark
          })
        },
        ...(ownerState.readOnly && {
          pointerEvents: "none"
        }),
        "& legend": { display: "none" }
      })
    }
  }
};
