import camelcaseKeys from "camelcase-keys";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";
import { resetUserState } from "../../components/login/userSlice";
import { convertObjectToSnakeCase } from "../../lib/helpers";

const tagTypes = [
  "partners",
  "clients",
  "sites",
  "environments",
  "site sections",
  "site configuration items"
];

export const ccApiBaseUrl =
  import.meta.env.VITE_CC_API_URL || window.location.origin;

const baseQuery = fetchBaseQuery({
  baseUrl: ccApiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState)?.user?.accessToken || "";

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  }
});

const baseQueryWithErrorHandling: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const formattedArgs: FetchArgs = {
    ...args,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...(args?.body && { body: convertObjectToSnakeCase(args.body) })
  };

  const result = await baseQuery(formattedArgs, api, extraOptions);

  if (result.error) {
    if (
      result.error.status === 401 ||
      ("originalStatus" in result.error && result.error.originalStatus === 401)
    ) {
      api.dispatch(resetUserState());
    }
  }
  const formattedResult = result?.data
    ? {
        ...result,
        // transform api reponse from snake case to camel case
        data: camelcaseKeys(result.data, {
          deep: true
        })
      }
    : result;

  return formattedResult;
};

export const clientConfigApi = createApi({
  baseQuery: baseQueryWithErrorHandling,
  refetchOnReconnect: true,
  endpoints: () => ({}),
  tagTypes
});
