import { FormControl, FormLabel, MenuItem, Select, Stack } from "@mui/material";
import ArrowDown from "@locaisolutions/icons/dist/icons20px/ArrowDoubleDown20Px";
import CustomButton from "../CustomButton";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAddNewEnvironmentToSiteMutation } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { setInitialSiteOperationDetails } from "../site/siteSlice";
import { updateUserMessagesList } from "../messagePopper/messagePopperSlice";
import { getCurrentlyUsedEnvironmentsForSelectedSite } from "../../redux/selectors/siteSelectors";

type SelectEnvironmentToCopyFromContentProps = {
  onClose: () => void;
  onNewAddedEnvironmentSuccess: (
    newSiteData: SiteConfigurationOperationResponse
  ) => void;
};

const SelectEnvironmentToCopyFromContent = (
  props: SelectEnvironmentToCopyFromContentProps
) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const availableEnvToCopyFrom = useSelector(
    getCurrentlyUsedEnvironmentsForSelectedSite
  );
  const selectedSiteId = selectedDrawersData.site?.id;

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsDetailsById = data?.environmentsDetailsById;
  const fullListOfEnvironments = environmentsDetailsById
    ? Object.keys(environmentsDetailsById)
    : [];

  const [addNewEnvironmentToSite, { isLoading }] =
    useAddNewEnvironmentToSiteMutation();

  const form = useForm<{ envToCopyFrom: string; envToCopyTo: string }>({
    mode: "onChange"
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid }
  } = form;

  const availableEnvToCopyTo = useMemo(() => {
    const filteredlist = fullListOfEnvironments.filter(
      (envId) => !availableEnvToCopyFrom.includes(envId)
    );
    if (!filteredlist.length) return fullListOfEnvironments;
    return filteredlist;
  }, [availableEnvToCopyFrom, fullListOfEnvironments]);

  if (!environmentsDetailsById) return <></>;

  const onSubmit: SubmitHandler<{
    envToCopyFrom: string;
    envToCopyTo: string;
  }> = async (data) => {
    if (!selectedSiteId) return;
    try {
      const newSiteData = await addNewEnvironmentToSite({
        siteId: selectedSiteId,
        environmentData: {
          newEnvironmentId: data.envToCopyTo,
          fromEnvironmentId: data.envToCopyFrom
        }
      }).unwrap();
      dispatch(
        setInitialSiteOperationDetails({
          selectedEnvironmentsForOperation: [
            ...availableEnvToCopyFrom,
            data.envToCopyTo
          ],
          mode: "newSiteEnvironment"
        })
      );
      props.onNewAddedEnvironmentSuccess(newSiteData);
    } catch (err) {
      if (environmentsDetailsById)
        dispatch(
          updateUserMessagesList({
            message: `Unable to add new ${environmentsDetailsById[watch("envToCopyTo")].environmentName} environment.`,
            type: "error"
          })
        );
    }
  };

  return (
    <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
      <Stack
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          flex: 1,
          my: 3,
          gap: 8,
          mx: 2
        }}
      >
        <Stack flexDirection="column" gap={3} alignItems="center">
          <FormControl
            sx={{
              minWidth: "250px"
            }}
          >
            <FormLabel
              id="env-to-copy-from-select"
              component="legend"
              sx={{
                marginLeft: "3px",
                marginBottom: "5px"
              }}
            >
              {"Environment to copy from:"}
            </FormLabel>
            <Controller
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  labelId="env-to-copy-from-select"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                >
                  {availableEnvToCopyFrom.map((envId, i) => (
                    <MenuItem
                      value={envId}
                      key={`env-to-copy-from-${environmentsDetailsById[envId].environmentName}${i}`}
                    >
                      {environmentsDetailsById[envId].environmentName}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={"envToCopyFrom"}
              control={control}
              defaultValue={""}
              rules={{
                required: true
              }}
            />
          </FormControl>
          <ArrowDown height={40} width={40} />
          <FormControl
            sx={{
              minWidth: "250px"
            }}
          >
            <FormLabel
              id="env-to-copy-to-select"
              component="legend"
              sx={{
                marginLeft: "3px",
                marginBottom: "5px"
              }}
            >
              {"Environment to copy to:"}
            </FormLabel>
            <Controller
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  labelId="env-to-copy-to-select"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                >
                  {availableEnvToCopyTo.map((envId, i) => (
                    <MenuItem
                      value={envId}
                      key={`env-to-copy-from-${environmentsDetailsById[envId].environmentName}${i}`}
                    >
                      {environmentsDetailsById[envId].environmentName}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name={"envToCopyTo"}
              control={control}
              defaultValue={""}
              rules={{
                required: true
              }}
            />
          </FormControl>
        </Stack>
        <Stack flexDirection="row" gap={3}>
          <CustomButton
            sx={{ height: 35 }}
            color="secondary"
            variant="contained"
            onClick={onClose}
          >
            Close
          </CustomButton>
          <CustomButton
            sx={{ minWidth: 180, height: 35 }}
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isLoading}
          >
            Add new environment
          </CustomButton>
        </Stack>
      </Stack>
    </form>
  );
};
export default SelectEnvironmentToCopyFromContent;
