import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import RequieredSiteFields, {
  ConfigurationItemName
} from "./RequieredSiteFields";
import { ModalMainContent } from "../duplicate/DuplicateSiteModal";
import { getInitialConfigurationValue } from "../../lib/siteHelpers";
import SiteOperationModalActionButtons from "./SiteOperationModalActionButtons";
import {
  getHasRequiredSiteOperationData,
  getSiteOperationTempData
} from "../../redux/selectors/siteSelectors";
import { isURL } from "../../lib/helpers";
import { basicColours } from "../../lib/colors";
import { setInitialSiteOperationDetails } from "../site/siteSlice";

const ClientDetailsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.secondary.main,
  justifyContent: "space-evenly",
  padding: "10px 0px"
}));

const ConfigurationItemContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
}));

const SiteSectionName = styled(Typography)(() => ({
  fontSize: "1.25rem",
  fontWeight: "500",
  color: basicColours.mono.black,
  alignSelf: "flex-start",
  padding: "15px 0px 10px 0px"
}));

const ConfigurationItemsContainer = styled(Box)(() => ({
  padding: "20px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  width: "100%"
}));

type RequiredConfigurationsItemsProps = {
  siteOperationData: SiteConfigurationOperationResponse;
  handleModalClose: () => void;
  clientName?: string;
  submitBtnName: string;
  onSubmitRequiredFieldChanges: (
    pendingChanges: PendingChangeType[],
    addionalNewSiteInfo: SiteConfigurationOperationResponse
  ) => void;
};

export type DuplicateSiteRequiredType = ConfigurationItemType & {
  currentValue: string | string[] | boolean | number | null;
  currentValuesPerEnv: {
    [envId in string]: string | string[] | boolean | number | null;
  };
};
export type RequiredSiteFieldsValidationErrors = {
  [configItemId in string]: {
    currentValueErrorMessage: string;
    currentValuesPerEnvValidationErrors: {
      [envId in string]: string;
    };
  };
};

const RequiredConfigurationsItems = (
  props: RequiredConfigurationsItemsProps
) => {
  const [requiredSiteFields, setRequireSiteFields] = React.useState<{
    [configItemId in string]: DuplicateSiteRequiredType;
  }>({});
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const dispatch = useDispatch();
  const hasRequiredSiteOperationDataRef = useRef(hasRequiredSiteOperationData);

  const [validationErrors, setValidationErrors] =
    React.useState<RequiredSiteFieldsValidationErrors>({});

  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const selectedEnvironmentsList =
    siteOperationTempData.selectedEnvironmentsList;

  const { clientName, siteOperationData, handleModalClose } = props;

  const requiredSiteConfigurationItems =
    siteOperationData?.requiredSiteConfigurationItems || [];
  const allConfigurationsMenuType =
    siteOperationData?.siteConfigurationsByMenuType.allConfigurationsMenuTypes;

  useEffect(() => {
    if (!requiredSiteConfigurationItems?.length) return;
    const currentRequiredFields = requiredSiteConfigurationItems.reduce<{
      [configItemId in string]: DuplicateSiteRequiredType;
    }>((acc, configurationItem: ConfigurationItemType) => {
      const { dataType, configurationItemId, validationRules } =
        configurationItem;

      const currentValuesPerEnv = selectedEnvironmentsList.reduce<{
        [configItemId in string]: ConfigurationValueInputType;
      }>((acc, currentEnvId: string) => {
        const currentValidationRule =
          validationRules.find((el) => el.environmentId === currentEnvId)
            ?.validationRules || null;

        const initialFieldValue =
          getInitialConfigurationValue(dataType, currentValidationRule) || "";
        return { ...acc, [currentEnvId]: initialFieldValue };
      }, {});

      return {
        ...acc,
        [configurationItemId]: {
          ...configurationItem,
          currentValue:
            getInitialConfigurationValue(
              dataType,
              // use first value since we assume we have same env validtaion rules for all envs
              validationRules[0].validationRules
            ) || "",
          currentValuesPerEnv
        }
      };
    }, {});

    const emptyValidationErrors =
      requiredSiteConfigurationItems.reduce<RequiredSiteFieldsValidationErrors>(
        (acc, el) => {
          const currentValuesPerEnvValidationErrors =
            selectedEnvironmentsList.reduce<{
              [configItemId in string]: string;
            }>((acc, currentEnvId) => {
              // eslint-disable-next-line no-param-reassign
              acc[currentEnvId] = "";
              return acc;
            }, {});

          // eslint-disable-next-line no-param-reassign
          acc[el.configurationItemId] = {
            currentValueErrorMessage: "",
            currentValuesPerEnvValidationErrors
          };
          return acc;
        },
        {}
      );

    setValidationErrors(emptyValidationErrors);
    setRequireSiteFields(currentRequiredFields);
  }, [requiredSiteConfigurationItems]);

  const updateConfigItemCurrentValue = (
    configurationItemId: string,
    currentValue: string | string[] | boolean | number | null,
    currentValueEnvId?: string
  ) => {
    const { uniquePerEnvironment } = requiredSiteFields[configurationItemId];
    if (
      uniquePerEnvironment &&
      currentValueEnvId &&
      !!requiredSiteFields[configurationItemId].currentValuesPerEnv
    ) {
      setRequireSiteFields((prevValue) => ({
        ...prevValue,
        [configurationItemId]: {
          ...prevValue[configurationItemId],
          currentValuesPerEnv: {
            ...prevValue[configurationItemId].currentValuesPerEnv,
            [currentValueEnvId]: currentValue
          }
        }
      }));
    } else {
      setRequireSiteFields((prevValue) => ({
        ...prevValue,
        [configurationItemId]: {
          ...prevValue[configurationItemId],
          currentValue
        }
      }));
    }
  };

  const updateValidationErrors = (
    errorMessage: string,
    configItemId: string,
    uniquePerEnvironment?: boolean,
    currentValueEnvId?: string
  ) => {
    setValidationErrors((prevValue) => ({
      ...prevValue,
      [configItemId]: {
        currentValueErrorMessage: uniquePerEnvironment ? "" : errorMessage,
        currentValuesPerEnvValidationErrors:
          uniquePerEnvironment && currentValueEnvId
            ? {
                ...prevValue[configItemId].currentValuesPerEnvValidationErrors,
                [currentValueEnvId]: errorMessage
              }
            : { ...prevValue[configItemId].currentValuesPerEnvValidationErrors }
      }
    }));
  };

  const validateRequiredFields = (): boolean => {
    let hasValidationErrors = false;
    Object.keys(requiredSiteFields).forEach((configItemId: string) => {
      const {
        currentValue,
        uniquePerEnvironment,
        currentValuesPerEnv,
        validationRules,
        dataType
      } = requiredSiteFields[configItemId];

      if (!uniquePerEnvironment) {
        if (validationRules[0].isRequired && !currentValue) {
          updateValidationErrors("No empty values allowed.", configItemId);
          hasValidationErrors = true;
        } else if (dataType === "url" && typeof currentValue === "string") {
          if (!isURL(currentValue)) {
            updateValidationErrors("Must be a valid URL value.", configItemId);
            hasValidationErrors = true;
          }
        }
      } else if (uniquePerEnvironment && currentValuesPerEnv) {
        Object.keys(currentValuesPerEnv).forEach((envId, i, envArr) => {
          const currentSingleValuePerEnv = currentValuesPerEnv[envId];
          const currentValidationRule = validationRules.find(
            (el) => el.environmentId === envId
          );
          const nextSingleValuePerEnv =
            i < envArr.length ? currentValuesPerEnv[envArr[i + 1]] : null;

          if (!currentSingleValuePerEnv && currentValidationRule?.isRequired) {
            updateValidationErrors(
              "No empty values allowed.",
              configItemId,
              uniquePerEnvironment,
              envId
            );
            hasValidationErrors = true;
            return;
          }
          if (
            dataType === "url" &&
            typeof currentSingleValuePerEnv === "string" &&
            currentSingleValuePerEnv
          ) {
            if (!isURL(currentSingleValuePerEnv)) {
              updateValidationErrors(
                "Must be a valid URL value.",
                configItemId,
                uniquePerEnvironment,
                envId
              );
              hasValidationErrors = true;
              return;
            }
          }
          if (
            currentSingleValuePerEnv &&
            nextSingleValuePerEnv &&
            typeof currentSingleValuePerEnv === "string" &&
            typeof nextSingleValuePerEnv === "string" &&
            currentSingleValuePerEnv?.trim() === nextSingleValuePerEnv?.trim()
          ) {
            updateValidationErrors(
              "URL values must be unique per environment.",
              configItemId,
              uniquePerEnvironment,
              envId
            );
            updateValidationErrors(
              "URL values must be unique per environment.",
              configItemId,
              uniquePerEnvironment,
              envArr[i + 1]
            );
            hasValidationErrors = true;
            return;
          }
        });
      }
    });
    if (hasValidationErrors) return true;
    // check for additional errors
    Object.keys(validationErrors).forEach((configItemId) => {
      const isUniquePerEnv =
        requiredSiteFields[configItemId].uniquePerEnvironment || false;
      const validationError = validationErrors[configItemId];
      const { currentValueErrorMessage, currentValuesPerEnvValidationErrors } =
        validationError;
      if (isUniquePerEnv) {
        Object.values(currentValuesPerEnvValidationErrors).forEach(
          (el: string) => {
            if (el) {
              hasValidationErrors = true;
              return;
            }
          }
        );
        if (hasValidationErrors) return;
      }
      if (currentValueErrorMessage) {
        hasValidationErrors = true;
        return;
      }
    });

    return hasValidationErrors;
  };
  useEffect(() => {
    hasRequiredSiteOperationDataRef.current = hasRequiredSiteOperationData;
  }, [hasRequiredSiteOperationData]);

  useEffect(() => {
    return () => {
      if (!hasRequiredSiteOperationDataRef.current) {
        dispatch(
          setInitialSiteOperationDetails({
            mode: null,
            selectedEnvironmentsForOperation: []
          })
        );
      }
    };
  }, []);

  const onSubmitRequiredFieldChanges = () => {
    const hasValidationErrors = validateRequiredFields();

    if (hasValidationErrors) return;

    const pendingChanges = Object.values(
      requiredSiteFields
    ).map<PendingChangeType>((requiredField: DuplicateSiteRequiredType) => {
      const {
        dataType,
        configurationId,
        configurationTypeId,
        name,
        configurationItemId,
        currentValue,
        uniquePerEnvironment,
        currentValuesPerEnv,
        validationRules,
        description,
        integrationName
      } = requiredField;

      let currentConfigurationItemValues: ConfigurationValue[] = [];
      if (uniquePerEnvironment) {
        currentConfigurationItemValues = Object.keys(currentValuesPerEnv).map(
          (currentEnvId: string) => {
            const currentValuePerEnv = currentValuesPerEnv[currentEnvId];
            return {
              configurationValue:
                typeof currentValuePerEnv === "string"
                  ? currentValuePerEnv.trim()
                  : currentValuePerEnv,
              environmentIds: [currentEnvId]
            };
          }
        );
      } else {
        currentConfigurationItemValues = [
          {
            configurationValue:
              typeof currentValue === "string"
                ? currentValue.trim()
                : currentValue,
            environmentIds: selectedEnvironmentsList
          }
        ];
      }

      return {
        dataType,
        sectionId: configurationId,
        sectionName:
          allConfigurationsMenuType[configurationTypeId].configurationTypeName,
        configurationName: name,
        integrationName,
        description,
        configurationId,
        configurationTypeId,
        configurationItemId,
        uniquePerEnvironment,
        originalConfigurationItemValues: [],
        currentConfigurationItemValues,
        isPartOfSiteDuplicationOrCreation: true,
        validationRules
      };
    });
    props.onSubmitRequiredFieldChanges(pendingChanges, siteOperationData);

    queueMicrotask(() => {
      handleModalClose();
    });
  };
  if (!requiredSiteConfigurationItems?.length) return <></>;

  return (
    <ModalMainContent aria-label="required configuration items section">
      <ConfigurationItemsContainer>
        {clientName && (
          <ClientDetailsContainer>
            <ConfigurationItemName
              sx={{ flex: 1 }}
              extraProps={{
                uniquePerEnvironment: false
              }}
            >
              Client:
            </ConfigurationItemName>
            <Box
              sx={{
                minWidth: 200,
                flex: 1
              }}
            >
              <ConfigurationItemName
                sx={{ textAlign: "left" }}
                extraProps={{
                  uniquePerEnvironment: false
                }}
                data-testid="client-name"
              >
                {clientName}
              </ConfigurationItemName>
            </Box>
          </ClientDetailsContainer>
        )}
        {Object.values(requiredSiteFields).map((currentConfigItem, i, arr) => {
          const {
            configurationTypeId: currentConfigurationGroupingId,
            configurationItemId
          } = currentConfigItem;
          let currentSectionName = "";
          const prevConfigItem = i > 0 ? arr[i - 1] : null;

          if (
            prevConfigItem?.configurationTypeId !==
            currentConfigItem.configurationTypeId
          ) {
            currentSectionName =
              allConfigurationsMenuType?.[currentConfigurationGroupingId]
                .configurationTypeName;
          }

          return (
            <ConfigurationItemContainer
              key={`configuration-item-${currentConfigItem.configurationItemId}${i}`}
            >
              <SiteSectionName>{currentSectionName || ""}</SiteSectionName>
              <RequieredSiteFields
                configurationItem={currentConfigItem}
                updateConfigItemCurrentValue={updateConfigItemCurrentValue}
                validationErrors={validationErrors[configurationItemId]}
                setValidationError={updateValidationErrors}
                selectedEnvironmentsList={selectedEnvironmentsList}
              />
            </ConfigurationItemContainer>
          );
        })}
      </ConfigurationItemsContainer>
      <SiteOperationModalActionButtons
        onSubmitCb={onSubmitRequiredFieldChanges}
        onCancelCb={handleModalClose}
        submitBtnName={props.submitBtnName}
        hasSelectedEnvironmentsList
      />
    </ModalMainContent>
  );
};

export default RequiredConfigurationsItems;
