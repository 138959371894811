import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@locaisolutions/icons/dist/icons24px/Edit24Px";

import {
  getHasRequiredSiteOperationData,
  getPendingConfigurationItemsList,
  getSelectedSiteSection
} from "../../redux/selectors/siteSelectors";
import {
  SiteOperationTempDataType,
  updatePendingChangesList
} from "./siteSlice";
import { useGetConfigurationItemsListByConfigurationQuery } from "../../redux/api/site";

import ConfigurationItem from "./ConfigurationItem";
import { getPendingChangeConfigurationId } from "../../lib/siteHelpers";
import { useAppDispatch } from "../../store";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import ErrorPanel from "../ErrorPanel";
import { IconButton, Tooltip } from "@mui/material";
import EditSiteSectionModal from "./EditSiteSectionModal";

const MainContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "8px 0px 12px 0px",
  gap: 20,
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRadius: "6px"
}));

const ItemsContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "30px",
  margin: "0 10px"
}));

export const LoadingSpinner = (props: { size?: number; color?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
      }}
    >
      <CircularProgress
        color="primary"
        size={props.size || 40}
        sx={{
          color: props.color || "primary.main"
        }}
      />
    </Box>
  );
};

export type SelectedConfigurationItemType = {
  configurationName: string;
  configurationItemId: string;
  originalValues: ConfigurationValue[];
  currentSelectedConfigValue: ConfigurationItemType | null;
};

type ConfigurationItemsPropsType = {
  siteOperationTempData: SiteOperationTempDataType | null;
};

function ConfigurationItems(props: ConfigurationItemsPropsType) {
  const { siteOperationTempData } = props;
  const {
    allSiteConfigurationsByConfigurationId,
    isSiteOperationPending,
    mode
  } = siteOperationTempData || {};

  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const isInNewSiteCreatingMode =
    mode === "newSiteCreation" && hasRequiredSiteOperationData;
  const isInNewAddedEnvironmentMode =
    mode === "newSiteEnvironment" && hasRequiredSiteOperationData;

  const dispatch = useAppDispatch();
  const [isEditSectionModalOpen, updateSiteSectionModalStatus] =
    useState(false);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const isPendingChangesListDrawerDisplayed = !!Object.keys(
    pendingConfigurationChangesList
  )?.length;
  const selectedSiteDrawerData = useSelector(getSelectedDrawersData).site;

  const selectedSiteSection = useSelector(getSelectedSiteSection);

  const { name: configurationName, id: sectionId } = selectedSiteSection;
  const siteOperationConfigurationItems =
    allSiteConfigurationsByConfigurationId?.[sectionId]?.configurationItems ||
    [];
  const [selectedConfigurationItem, setSelectedConfigurationItem] =
    useState<SelectedConfigurationItemType>({
      configurationName: "",
      configurationItemId: "",
      originalValues: [],
      currentSelectedConfigValue: null
    });

  const { data, isLoading, isError, refetch, isFetching } =
    useGetConfigurationItemsListByConfigurationQuery(
      {
        configurationId: sectionId
      },
      {
        skip: !sectionId || hasRequiredSiteOperationData,
        refetchOnMountOrArgChange: true
      }
    );

  useEffect(() => {
    const myDiv = document.getElementById("config-items-section");

    if (myDiv?.scrollTo) {
      myDiv.scrollTop = 0;
    }
  }, [selectedSiteSection]);

  useEffect(() => {
    return () => {
      clearSelectedConfigItem();
    };
  }, []);

  const updateSelectedConfigurationItem = (
    value: SelectedConfigurationItemType
  ) => {
    setSelectedConfigurationItem(value);
  };
  const clearSelectedConfigItem = () => {
    setSelectedConfigurationItem({
      configurationName: "",
      configurationItemId: "",
      originalValues: [],
      currentSelectedConfigValue: null
    });
  };

  const saveSelectedConfigurationItemChanges = (
    pendingChange: PendingChangeType
  ) => {
    dispatch(
      updatePendingChangesList({
        pendingChange: {
          ...pendingChange,
          sectionName: selectedSiteSection.name,
          sectionId: selectedSiteSection.id
        },
        siteId: selectedSiteDrawerData?.id || ""
      })
    );
    clearSelectedConfigItem();
  };
  if (isError)
    return (
      <ErrorPanel
        errorMessage={`Failed to retrieve configuration information for ${configurationName} section.`}
        sx={{ gridColumn: "2/3", mt: 7, height: "100px", margin: "40px 30px" }}
        onRetryCb={async () => {
          await refetch();
        }}
      />
    );

  if (
    isLoading ||
    isFetching ||
    (!data && !hasRequiredSiteOperationData) ||
    isSiteOperationPending
  )
    return <LoadingSpinner />;

  const configurationItemsList = hasRequiredSiteOperationData
    ? siteOperationConfigurationItems
    : (data && data.configurationItems) || [];
  const currentSectionId = (data && data.configurationId) || sectionId;

  return (
    <MainContainer
      id="config-items-section"
      sx={{
        gridColumn: "2/3"
      }}
    >
      <Stack flexDirection="row" alignItems="center" gap={1} px={1} py={2}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: 500
          }}
        >
          {configurationName}
        </Typography>
        {!isInNewSiteCreatingMode && (
          <Tooltip title="Edit section details">
            <span>
              <IconButton
                onClick={() => updateSiteSectionModalStatus(true)}
                disabled={isInNewAddedEnvironmentMode}
                aria-label="Edit section details button"
              >
                <EditIcon height={25} width={25} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>

      <ItemsContainer
        sx={{
          ...(isPendingChangesListDrawerDisplayed && {
            "@media (max-width: 1400px)": {
              margin: "0px 5px"
            }
          })
        }}
      >
        {configurationItemsList.map((item, index) => {
          const { configurationItemId, name } = item;

          const possiblePendingConfigurationItemId =
            getPendingChangeConfigurationId(configurationItemId, sectionId);
          const possiblePendingChangeConfiguration =
            pendingConfigurationChangesList[possiblePendingConfigurationItemId];

          const isConfigurationItemPartOfPendingChanges =
            !!possiblePendingChangeConfiguration;
          const isCurrentSectionConfigurationSelected =
            currentSectionId === possiblePendingChangeConfiguration?.sectionId;

          const configurationItem =
            isConfigurationItemPartOfPendingChanges &&
            isCurrentSectionConfigurationSelected
              ? {
                  ...item,
                  configurationValues:
                    possiblePendingChangeConfiguration.currentConfigurationItemValues
                }
              : item;
          return (
            <ConfigurationItem
              selectedSectionItem={configurationItem}
              key={`item-${configurationItemId}-${name}${index}`}
              configKey={`item-${configurationItemId}-${name}${index}`}
              selectedConfigurationItem={selectedConfigurationItem}
              saveSelectedConfigurationItemChanges={
                saveSelectedConfigurationItemChanges
              }
              isPartOfPendingChangesList={
                isConfigurationItemPartOfPendingChanges &&
                isCurrentSectionConfigurationSelected
              }
              clearSelectedConfigItem={clearSelectedConfigItem}
              updateSelectedConfigurationItem={updateSelectedConfigurationItem}
              hasRequiredSiteOperationData={hasRequiredSiteOperationData}
            />
          );
        })}
      </ItemsContainer>
      <EditSiteSectionModal
        isEditSectionModalOpen={isEditSectionModalOpen}
        onClose={() => updateSiteSectionModalStatus(false)}
      />
    </MainContainer>
  );
}

export default ConfigurationItems;
