import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";

import ChevronLeftIcon from "@locaisolutions/icons/dist/icons24px/ArrowLeft24Px";
import ChevronRightIcon from "@locaisolutions/icons/dist/icons24px/ArrowRight24Px";

import {
  Stack,
  Typography,
  Divider,
  Collapse,
  Box,
  Toolbar
} from "@mui/material";

import {
  getNumbersOfDrawersWithContentSelected,
  getDrawerNameToDisplayByType
} from "../../lib/helpers";
import {
  toggleSelectedDrawer,
  onDrawerOptionBtnClick,
  SelectedSingleDrawerDataType
} from "./mainCustomDrawerSlice";
import {
  getRootDrawer,
  getCurrentDrawerStateProperties,
  getCurrentOpenDrawerType,
  getSelectedDrawersData
} from "../../redux/selectors/drawerSelectors";
import PartnersDrawerContent from "./PartnersDrawerContent";
import ClientsDrawerContent from "./ClientsDrawerContent";
import SitesDrawerContent from "./SitesDrawerContent";
import { saveRecentSearches } from "../home/homepageSlice";
import { drawerWidth, navBarHeight } from "../../lib/theme";
import { useAppDispatch } from "../../store";
import { mainColours } from "../../lib/colors";
import { useEffect } from "react";
import { useConfirmActionDialog } from "../useConfirmActionDialog";
import { getHasRequiredSiteOperationData } from "../../redux/selectors/siteSelectors";

type CustomDrawerProps = {
  drawerTypeName: DrawerType;
};

const CustomDrawerPreview = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    isDrawerDisabled: boolean;
    shouldDisplayExpandedUI: boolean;
    isDrawerOpen: boolean;
  };
}>(({ extraProps }) => ({
  display: "grid",
  gridTemplateColumns: !extraProps.isDrawerOpen
    ? "1fr"
    : `60px minmax(100px, ${drawerWidth}px)`,
  gridTemplateRows: !extraProps.isDrawerOpen
    ? "60px minmax(120px, 1fr)"
    : "auto",
  alignItems: "center",
  justifyItems: "center",
  cursor: extraProps.isDrawerDisabled ? "not-allowed" : "pointer",
  height: extraProps.isDrawerOpen ? 60 : "50%"
}));

const CustomDrawerTypeText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDrawerOpen: boolean; shouldDisplayExpandedUI: boolean };
}>(({ extraProps }) => ({
  transform: extraProps.isDrawerOpen ? "none" : "rotate(-90deg)",
  fontSize: 16,
  fontWeight: 500,
  marginRight: extraProps.isDrawerOpen ? "none" : "10px",
  whiteSpace: extraProps.isDrawerOpen ? "normal" : "nowrap"
}));

const CustomDrawerMainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDrawerOpen: boolean; isDrawerDisabled: boolean };
}>(({ extraProps, theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: "none",
  backgroundColor: extraProps.isDrawerDisabled
    ? mainColours.background.disabled
    : theme.palette.background.paper,
  "&:hover": {
    cursor: extraProps.isDrawerDisabled ? "not-allowed" : "default"
  },
  overflow: "scroll",
  overflowY: "hidden",
  height: "100%",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none"
}));

function CustomDrawer(props: CustomDrawerProps) {
  const { drawerTypeName } = props;
  const dispatch = useAppDispatch();
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { openDialog, resetDialogState: closeDialog } =
    useConfirmActionDialog();
  const {
    isOpen = false,
    isDisabled: isDrawerDisabled = false,
    nextDrawer,
    previousDrawer
  } = useSelector(getCurrentDrawerStateProperties(drawerTypeName));
  const rootDrawer = useSelector(getRootDrawer);
  const currentOpenDrawerType = useSelector(getCurrentOpenDrawerType);
  const selectedDrawersData = useSelector(getSelectedDrawersData);

  const drawerNameToDisplay = getDrawerNameToDisplayByType(drawerTypeName);

  const numberOfDrawersWithSelectedContent =
    getNumbersOfDrawersWithContentSelected(selectedDrawersData);

  const isRootDrawer = rootDrawer.drawerName === drawerTypeName;
  const isCurrentDrawerSelected = currentOpenDrawerType === drawerTypeName;
  const isDrawerOpen = isOpen && !isDrawerDisabled;

  const shouldDisplayExpandedUI =
    numberOfDrawersWithSelectedContent > 0 &&
    !isDrawerOpen &&
    selectedDrawersData[drawerTypeName];

  const handleReset = () => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    //handles additional cleanup after action `clearSiteOperationTempData` is triggered
    if (currentOpenDrawerType === "site") {
      handleReset();
    }
  }, [currentOpenDrawerType]);

  const toggleDrawer = (
    drawerTypeName: DrawerType,
    selectedDrawerValue?: boolean
  ): void => {
    if (hasRequiredSiteOperationData) {
      openDialog({
        title: "Unsaved Changes",
        message: (
          <Typography>
            You have unsaved changes.
            <br />
            Are you sure you want to leave without submitting current changes?
          </Typography>
        ),
        actionButtonsDetails: [
          {
            name: "Stay",
            variant: "subtle",
            actionCb: () => {
              closeDialog();
            }
          },
          {
            name: "Leave",
            variant: "serious",
            actionCb: () => {
              closeDialog();
              dispatch(
                toggleSelectedDrawer({
                  drawerType: drawerTypeName,
                  isDrawerOpenValue: selectedDrawerValue || false
                })
              );
              handleReset();
            }
          }
        ]
      });
    } else {
      dispatch(
        toggleSelectedDrawer({
          drawerType: drawerTypeName,
          isDrawerOpenValue: selectedDrawerValue || false
        })
      );
      handleReset();
    }
  };

  const onArrowBtnClick = () => {
    if (isDrawerDisabled) return;

    if (previousDrawer && isCurrentDrawerSelected) {
      toggleDrawer(drawerTypeName, false);
      //@ts-ignore
      toggleDrawer(previousDrawer, true);
    } else if (
      // if we are selecting the root drawer by clicking on "arrow back" icon to close all drawers
      isRootDrawer &&
      (isCurrentDrawerSelected ||
        (!currentOpenDrawerType && !selectedDrawersData[drawerTypeName]))
    ) {
      toggleDrawer(drawerTypeName, !isDrawerOpen);
    } else if (
      // if the current drawer we are selecting is "site"/last after returning from a site details view
      !currentOpenDrawerType &&
      selectedDrawersData[drawerTypeName] &&
      drawerTypeName === "site"
    ) {
      toggleDrawer("site", true);
      // if we are selecting any other drawer that is not the "site"/last drawer
      // allows users to switch drawers at will regardless of order
    } else if (drawerTypeName !== "site" && !isCurrentDrawerSelected) {
      toggleDrawer(drawerTypeName, true);
    }
  };

  const onDrawerMenuBtnClick = (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => {
    dispatch(
      onDrawerOptionBtnClick({
        drawerType: drawerTypeName,
        selectedDrawerOption,
        nextDrawerTypeToOpen: nextDrawer
      })
    );
    if (drawerTypeName === "site" && parentDetails)
      navigate(`/site/${selectedDrawerOption.id}`);
    if (parentDetails)
      dispatch(
        saveRecentSearches({
          siteId: selectedDrawerOption.id,
          siteName: selectedDrawerOption.name,
          clientName: parentDetails.name,
          clientId: parentDetails.id
        })
      );
  };

  const renderDrawerContentList = () => {
    switch (drawerTypeName) {
      case "partner":
        return (
          <PartnersDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
      case "client":
        return (
          <ClientsDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
      case "site":
        return (
          <SitesDrawerContent
            onDrawerMenuBtnClick={onDrawerMenuBtnClick}
            drawerType={drawerTypeName}
          />
        );
    }
  };

  const fullDrawerNameToDisplay = selectedDrawersData[drawerTypeName]
    ? `${drawerNameToDisplay} - ${selectedDrawersData[drawerTypeName]?.name}`
    : drawerNameToDisplay;

  return (
    <CustomDrawerMainContainer
      extraProps={{
        isDrawerOpen,
        isDrawerDisabled
      }}
      data-testid={`custom-drawer-${drawerTypeName}`}
    >
      <Toolbar sx={{ flex: `0 1 ${navBarHeight}` }} />
      <Stack
        flexDirection="column"
        alignItems="center"
        sx={{
          height: !isDrawerOpen ? "100%" : "auto",
          width: isDrawerOpen ? drawerWidth : 60
        }}
      >
        <CustomDrawerPreview
          onClick={onArrowBtnClick}
          extraProps={{
            shouldDisplayExpandedUI: !!shouldDisplayExpandedUI,
            isDrawerDisabled,
            isDrawerOpen
          }}
          data-testid={`custom-drawer-${drawerTypeName}-toggle-btn`}
        >
          {isDrawerOpen ? (
            <ChevronLeftIcon
              aria-label="close-content-arrow-icon"
              width={18}
              height={18}
            />
          ) : (
            <ChevronRightIcon
              aria-label="open-content-arrow-icon"
              width={18}
              height={18}
            />
          )}

          <CustomDrawerTypeText
            extraProps={{
              shouldDisplayExpandedUI: !!shouldDisplayExpandedUI,
              isDrawerOpen
            }}
            data-testid={`custom-drawer-title-${drawerTypeName}`}
          >
            {fullDrawerNameToDisplay}
          </CustomDrawerTypeText>
        </CustomDrawerPreview>

        {isDrawerOpen && <Divider sx={{ width: "95%", margin: "5px 0px" }} />}
      </Stack>
      <Collapse
        in={isDrawerOpen}
        orientation="horizontal"
        sx={{
          transition: "450ms cubic-bezier(0.23, 1, 0.32, 1) 2ms",
          "& .MuiCollapse-wrapperInner": {
            width: "200px",
            height: "calc(100vh - 140px)"
          }
        }}
      >
        {renderDrawerContentList()}
      </Collapse>
    </CustomDrawerMainContainer>
  );
}

export default CustomDrawer;
