import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import { ModalMainContent } from "./DuplicateSiteModal";
import SiteOperationModalActionButtons from "../siteOperations/SiteOperationModalActionButtons";
import { setInitialSiteOperationDetails } from "../site/siteSlice";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { Stack } from "@mui/material";
import { getCurrentlyUsedEnvironmentsForSelectedSite } from "../../redux/selectors/siteSelectors";

const EnvValuesContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "5px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  justifyContent: "center",
  alignItems: "center"
}));

const EnvSelectionContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "5px",
  flex: 0.9,
  justifyContent: "center"
}));

const RequieredDuplicateSiteInformation = (props: {
  handleModalClose: () => void;
}) => {
  const { handleModalClose } = props;
  const [selectedEnvironmentsForOperation, setSelectedEnvironments] = useState<
    string[]
  >([]);
  const dispatch = useAppDispatch();
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const currentlyUsedEnvironmentsForCurrentSite = useSelector(
    getCurrentlyUsedEnvironmentsForSelectedSite
  );

  const { data: environmentsData } = useGetEnvironmentsListQuery(undefined);
  const environmentsDetailsById = environmentsData?.environmentsDetailsById;

  const updateSelectedEnvList = (envId: string) => {
    if (selectedEnvironmentsForOperation.includes(envId)) {
      const updatedSelectedEnvLisToDuplicateTo =
        selectedEnvironmentsForOperation.filter(
          (selectedEnvId) => selectedEnvId !== envId
        );
      setSelectedEnvironments(updatedSelectedEnvLisToDuplicateTo);
    } else {
      setSelectedEnvironments((prevEnvList) => [...prevEnvList, envId]);
    }
  };

  const submitListOfSelectedEnvironmentsToDuplicateTo = () => {
    if (selectedEnvironmentsForOperation.length) {
      dispatch(
        setInitialSiteOperationDetails({
          selectedEnvironmentsForOperation,
          ...(selectedDrawersData.site && {
            fromSite: {
              id: selectedDrawersData.site.id,
              name: selectedDrawersData.site.name
            }
          }),
          mode: "newSiteCreation"
        })
      );
    }
  };

  const clearModalStatus = () => {
    setSelectedEnvironments([]);
  };

  useEffect(() => {
    return () => {
      clearModalStatus();
    };
  }, []);
  if (!environmentsDetailsById) return <></>;
  return (
    <ModalMainContent
      sx={{
        alignItems: "center",
        flex: 1
      }}
      data-testid="env-selection-content"
    >
      <EnvSelectionContainer>
        <Typography
          sx={{
            fontSize: "1.2rem",
            textAlign: "center",
            margin: "5px 20px"
          }}
        >
          Please selected the environments you wish to duplicate to:
        </Typography>
        <EnvValuesContainer>
          {currentlyUsedEnvironmentsForCurrentSite.map((envId) => {
            const { environmentName, enabled = false } =
              environmentsDetailsById[envId] || {};
            const isEnvSelected =
              !!selectedEnvironmentsForOperation.includes(envId);
            return (
              <Stack
                key={`env-container-checkbox-${envId}`}
                data-testid={"env-container-checkbox"}
                sx={{
                  alignItems: "center",
                  opacity: !enabled ? 0.2 : 2,
                  gap: 2,
                  flexDirection: "row"
                }}
              >
                <Checkbox
                  aria-label={environmentName}
                  id={`env-checkbox-${envId}`}
                  checked={isEnvSelected}
                  onClick={() => updateSelectedEnvList(envId)}
                  disabled={!enabled}
                  style={{
                    height: 26,
                    width: 26
                  }}
                />
                <Typography
                  id={`env-label-${envId}`}
                  sx={{ minWidth: "100px" }}
                  variant="body1"
                >
                  {environmentName}
                </Typography>
              </Stack>
            );
          })}
        </EnvValuesContainer>
      </EnvSelectionContainer>
      <SiteOperationModalActionButtons
        sx={{
          marginBottom: "15px"
        }}
        onSubmitCb={submitListOfSelectedEnvironmentsToDuplicateTo}
        onCancelCb={handleModalClose}
        submitBtnName="Next"
      />
    </ModalMainContent>
  );
};

export default RequieredDuplicateSiteInformation;
