import { useEffect } from "react";
import { SiteOperationTempDataType } from "../components/site/siteSlice";
import {
  useLazyGetSitesListByClientQuery,
  useLazyPostDuplicateOrCreateSiteQuery
} from "../redux/api/site";
import { getConfigurationItemsFromPendingChanges } from "../lib/siteHelpers";

const usePostDuplicateOrCreateSiteHook = (
  siteOperationTempData: SiteOperationTempDataType,
  pendingConfigurationChangesList: PendingChangesListType,
  clientId?: string
) => {
  const {
    generalSiteInformation,
    fromSite,
    selectedEnvironmentsList,
    removedConfigurationIds
  } = siteOperationTempData;

  const [triggerPostDuplicateOrCreateSite, result] =
    useLazyPostDuplicateOrCreateSiteQuery();
  const [triggerFetchSiteListByClientId] = useLazyGetSitesListByClientQuery();

  const submitSiteToDuplicateOrCreateChanges = () => {
    if (!generalSiteInformation) return;

    const configurationItems: ConfigurationItemType[] =
      getConfigurationItemsFromPendingChanges(pendingConfigurationChangesList);

    const siteToDuplicateOrCreateData: SiteCreationRequestBody = {
      site: generalSiteInformation,
      fromSiteId: fromSite?.id || "",
      configurationItems,
      environmentIds: selectedEnvironmentsList,
      sendDeployments: false,
      removedConfigurationIds
    };

    triggerPostDuplicateOrCreateSite(siteToDuplicateOrCreateData);
  };

  useEffect(() => {
    if (result.isSuccess && !!result.data && clientId) {
      triggerFetchSiteListByClientId({ clientId });
    }
  }, [result.isSuccess, result.data, triggerFetchSiteListByClientId]);

  return {
    ...result,
    submitSiteToDuplicateOrCreateChanges
  };
};

export default usePostDuplicateOrCreateSiteHook;
