import { clientConfigApi } from ".";

export interface ClientType {
  clientId: string;
  name: string;
  salesForceId: string;
  partnerId: string;
  enabled: boolean;
}
export type GetClientsListResponse = ClientType[];

export const clientApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientsList: builder.query<CustomDrawerContentType[], void>({
      query: () => {
        return {
          url: `/api/Clients`,
          method: "GET"
        };
      },
      transformResponse: (
        response: GetClientsListResponse
      ): CustomDrawerContentType[] => {
        return response.map((clientData) => ({
          id: clientData.clientId,
          name: clientData.name,
          salesforceId: clientData.salesForceId
        }));
      },
      providesTags: ["clients"]
    })
  }),
  overrideExisting: false
});

export const { useGetClientsListQuery } = clientApi;
export default clientApi;
