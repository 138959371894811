import { ButtonProps } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";

export type ConfirmDialogActionButtonsType = {
  name: string;
  actionCb: () => void;
  icon?: JSX.Element;
  variant?: ButtonProps["variant"];
};
export const ConfirmActionDialogContext = createContext<{
  isOpen: boolean;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  actionButtonsDetails: ConfirmDialogActionButtonsType[];
  setActionButtons: (actionButtons: ConfirmDialogActionButtonsType[]) => void;
  setIsOpen: (isOpen: boolean) => void;
  setMessage: (message: string | React.ReactNode) => void;
  setTitle: (message: string | React.ReactNode) => void;
  resetDialogState: () => void;
}>(null as never);

export const ConfirmActionDialogProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string | React.ReactNode>("");
  const [message, setMessage] = useState<string | React.ReactNode>("");

  const [actionButtonsDetails, setActionButtons] = useState<
    ConfirmDialogActionButtonsType[]
  >([]);

  const resetDialogState = useCallback(() => {
    setIsOpen(false);
    setTitle("");
    setMessage("");
    setActionButtons([]);
  }, []);

  return (
    <ConfirmActionDialogContext.Provider
      value={{
        isOpen,
        title,
        message,
        actionButtonsDetails,
        setIsOpen,
        setTitle,
        setMessage,
        setActionButtons,
        resetDialogState
      }}
    >
      {children}
    </ConfirmActionDialogContext.Provider>
  );
};

type ConfirmActionDialogOptions = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  actionButtonsDetails: ConfirmDialogActionButtonsType[];
};

export const useConfirmActionDialog = () => {
  const dialogContext = useContext(ConfirmActionDialogContext);
  const { setIsOpen, setActionButtons, setMessage, setTitle } =
    dialogContext as {
      setIsOpen: (isOpen: boolean) => void;
      setActionButtons: (
        actionButtons: ConfirmDialogActionButtonsType[]
      ) => void;
      setMessage: (message: string | React.ReactNode) => void;
      setTitle: (title: string | React.ReactNode) => void;
    };

  const openDialog = useCallback(
    ({ title, message, actionButtonsDetails }: ConfirmActionDialogOptions) => {
      setTitle(title);
      setMessage(message);
      setActionButtons(actionButtonsDetails);
      setIsOpen(true);
    },
    []
  );

  return {
    ...dialogContext,
    openDialog
  };
};
