import { useSelector } from "react-redux";

import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import CustomDrawerContent from "./CustomDrawerContent";
import { useGetClientsListByPartnerQuery } from "../../redux/api/partner";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { useGetClientsListQuery } from "../../redux/api/client";
import ErrorPanel from "../ErrorPanel";

type ClientDrawerContentProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  isDrawerDisabled?: boolean;
  drawerType: DrawerType;
};

function ClientDrawerContent(props: ClientDrawerContentProps) {
  const { isDrawerDisabled } = props;
  const selectedPartnerDrawerData = useSelector(getSelectedDrawersData).partner;
  const partnerId = selectedPartnerDrawerData?.id || "";
  const clientsListByPartnerResponse = useGetClientsListByPartnerQuery(
    {
      partnerId
    },
    { skip: !partnerId, refetchOnMountOrArgChange: true }
  );
  const completeClientsListResponse = useGetClientsListQuery(undefined, {
    skip: !!partnerId || isDrawerDisabled,
    refetchOnMountOrArgChange: true
  });
  const {
    data: clientsList = [],
    isLoading,
    isError,
    refetch
  } = partnerId ? clientsListByPartnerResponse : completeClientsListResponse;

  if (isError)
    return (
      <ErrorPanel
        errorMessage="Failed to retrieve clients list."
        size="small"
        sx={{
          textAlign: "center"
        }}
        onRetryCb={async () => {
          await refetch();
        }}
      />
    );

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isLoading}
      drawerContentData={clientsList}
    />
  );
}

export default ClientDrawerContent;
