import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTransform } from "redux-persist";
import {
  navigateToRecentSite,
  onDrawerOptionBtnClick,
  resetDrawerStructure,
  toggleSelectedDrawer
} from "../drawer/mainCustomDrawerSlice";
import {
  emptySiteId,
  getPendingChangeConfigurationId,
  removeConfigurationFromConfigurationMenu
} from "../../lib/siteHelpers";
import siteApi from "../../redux/api/site";
import { resetUserState } from "../login/userSlice";

type OpenSiteView = "SiteDetails" | "AuditLog" | "HomePage" | "";

type SiteOperationType = "newSiteEnvironment" | "newSiteCreation";

export type RequiredSiteOperationDataPayload = {
  pendingChanges: PendingChangeType[];
  addionalNewSiteInfo: SiteConfigurationOperationResponse;
};

export type SiteOperationTempDataType = {
  selectedEnvironmentsList: string[];
  mode: SiteOperationType | null;
  fromSite: {
    id: string;
    name: string;
  } | null;
  siteConfigurationsByMenuType: SiteConfigurationResponseType;
  requiredSiteConfigurationItems: ConfigurationItemType[];
  generalSiteInformation: SiteGeneralInfo | null;
  requiredSiteFields: PendingChangeType[];
  allSiteConfigurationsByConfigurationId: AllConfigurationsbyConfigId | null;
  isSiteOperationPending: boolean;
  hasRequiredSiteDataReady: boolean;
  removedConfigurationIds: string[];
};

export type SiteStateType = {
  selectedSiteSection: {
    name: string;
    id: string;
    description: string;
    notes: string | null;
  };
  currentlyUsedEnvironmentIds: string[];
  currentOpenSiteView: OpenSiteView;
  pendingChangesList: PendingChangesListType;
  siteOperationTempData: SiteOperationTempDataType;
  savedPendingSiteEdits: { [siteId in string]: PendingChangesListType };
};

export type SetSelectedSiteSectionType = {
  configurationId: string;
  name: string;
  description: string;
  notes: string | null;
};

const initialState: SiteStateType = {
  selectedSiteSection: {
    name: "",
    id: "",
    description: "",
    notes: null
  },
  currentlyUsedEnvironmentIds: [],
  pendingChangesList: {},
  savedPendingSiteEdits: {},
  currentOpenSiteView: "HomePage",
  siteOperationTempData: {
    fromSite: null,
    generalSiteInformation: null,
    selectedEnvironmentsList: [],
    requiredSiteFields: [],
    siteConfigurationsByMenuType: {
      allConfigurationsMenuTypes: {},
      formattedConfigurationsByMainGroupings: {}
    },
    allSiteConfigurationsByConfigurationId: null,
    isSiteOperationPending: false,
    mode: null,
    requiredSiteConfigurationItems: [],
    hasRequiredSiteDataReady: false,
    removedConfigurationIds: []
  }
};

const siteStateSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    setSelectedSiteSection(
      state,
      action: PayloadAction<SetSelectedSiteSectionType>
    ) {
      const { configurationId, name, description, notes } = action.payload;

      state.selectedSiteSection = {
        id: configurationId,
        name,
        description,
        notes
      };
    },
    updateOpenSiteView(state, action) {
      const siteView = action.payload as OpenSiteView;
      state.currentOpenSiteView = siteView;
    },
    updatePendingChangesList(state, action) {
      const { pendingChange, siteId } = action.payload as {
        pendingChange: PendingChangeType;
        siteId: string;
      };
      const pendingConfigurationItemId = getPendingChangeConfigurationId(
        pendingChange.configurationItemId,
        pendingChange.sectionId
      );

      if (siteId !== emptySiteId && !state.siteOperationTempData.mode) {
        if (!state?.savedPendingSiteEdits?.[siteId]) {
          state.savedPendingSiteEdits = {
            ...state.savedPendingSiteEdits,
            [siteId]: {
              [pendingConfigurationItemId]: pendingChange
            }
          };
        } else {
          if (!Object.keys(state.pendingChangesList).length) {
            state.pendingChangesList = {
              ...state.savedPendingSiteEdits[siteId]
            };
          }
          state.savedPendingSiteEdits[siteId][pendingConfigurationItemId] =
            pendingChange;
        }
      }
      state.pendingChangesList[pendingConfigurationItemId] = pendingChange;
    },
    setInitialSiteOperationDetails(
      state,
      action: PayloadAction<{
        selectedEnvironmentsForOperation: string[];
        fromSite?: {
          id: string;
          name: string;
        };
        mode: SiteOperationType | null;
      }>
    ) {
      const { selectedEnvironmentsForOperation, fromSite, mode } =
        action.payload;
      state.siteOperationTempData.selectedEnvironmentsList =
        selectedEnvironmentsForOperation;
      state.siteOperationTempData.mode = mode;

      if (fromSite) state.siteOperationTempData.fromSite = fromSite;
    },
    clearSiteOperationTempData(
      state,
      action: PayloadAction<{ shouldHardReset: boolean }>
    ) {
      state.siteOperationTempData = { ...initialState.siteOperationTempData };
      state.pendingChangesList = {};
      if (action.payload.shouldHardReset) {
        if (state.selectedSiteSection.id) {
          state.selectedSiteSection = {
            ...initialState.selectedSiteSection
          };
        }

        state.currentOpenSiteView = "";
      }
    },
    deletePendingChangesListEntry(state, action) {
      const { pendingConfigurationItemId, siteId } = action.payload as {
        pendingConfigurationItemId: string;
        siteId: string;
      };
      let newPendingChangesList;

      if (state.pendingChangesList[pendingConfigurationItemId]) {
        newPendingChangesList = Object.assign({}, state.pendingChangesList);
      } else if (
        !!state?.savedPendingSiteEdits?.[siteId] &&
        state.savedPendingSiteEdits[siteId][pendingConfigurationItemId]
      ) {
        newPendingChangesList = Object.assign(
          {},
          state.savedPendingSiteEdits[siteId]
        );
      }

      if (newPendingChangesList) {
        delete newPendingChangesList[pendingConfigurationItemId];
        state.savedPendingSiteEdits[siteId] = newPendingChangesList;
        state.pendingChangesList = newPendingChangesList;
      }
    },
    removeSectionForSiteCreation(
      state,
      action: PayloadAction<SiteConfigurationMenuItem>
    ) {
      const sectionToBeRemoved = action.payload;
      const formattedConfigurationsByMainGroupings =
        removeConfigurationFromConfigurationMenu(
          state.siteOperationTempData.siteConfigurationsByMenuType,
          sectionToBeRemoved
        );
      const allSiteConfigurationsByConfigurationId = {
        ...state.siteOperationTempData.allSiteConfigurationsByConfigurationId
      };
      const newPendingChangesList = {
        ...state.pendingChangesList
      };

      if (
        allSiteConfigurationsByConfigurationId[
          sectionToBeRemoved.configurationId
        ]
      ) {
        delete allSiteConfigurationsByConfigurationId[
          sectionToBeRemoved.configurationId
        ];
      }
      for (const pendingChangeItem in newPendingChangesList) {
        const pendingChange = newPendingChangesList[pendingChangeItem];
        if (pendingChange.sectionId === sectionToBeRemoved.configurationId) {
          delete newPendingChangesList[pendingChangeItem];
        }
      }

      state.pendingChangesList = newPendingChangesList;
      state.siteOperationTempData.siteConfigurationsByMenuType.formattedConfigurationsByMainGroupings =
        formattedConfigurationsByMainGroupings;
      state.siteOperationTempData.allSiteConfigurationsByConfigurationId =
        allSiteConfigurationsByConfigurationId;
      state.siteOperationTempData.removedConfigurationIds = [
        ...state.siteOperationTempData.removedConfigurationIds,
        sectionToBeRemoved.configurationId
      ];
    },
    submitRequiredSiteOperationData(
      state,
      action: PayloadAction<RequiredSiteOperationDataPayload>
    ) {
      const { pendingChanges, addionalNewSiteInfo } = action.payload;
      //clear any leftover pending changes from other sites edits
      state.pendingChangesList = {};
      const {
        siteId,
        partnerId,
        clientId,
        salesforceId,
        name: originalSiteName,
        address1,
        address2,
        address3,
        city,
        country,
        siteConfigurationsByMenuType,
        allSiteConfigurationsByConfigurationId,
        requiredSiteConfigurationItems
      } = addionalNewSiteInfo;

      const newSiteName = (pendingChanges.find(
        (el) => el.configurationName.toLowerCase() === "name"
      )?.currentConfigurationItemValues[0]?.configurationValue ||
        originalSiteName) as string;
      const generalSiteInformation: SiteGeneralInfo = {
        siteId,
        partnerId,
        clientId,
        salesforceId,
        name: newSiteName,
        displayName: newSiteName,
        enabled: true,
        address1,
        address2,
        address3,
        city,
        country,
        state: addionalNewSiteInfo.state,
        timeZone: (pendingChanges.find(
          (el) => el.configurationName.toLowerCase() === "timezone"
        )?.currentConfigurationItemValues[0]?.configurationValue ?? null) as
          | string
          | null
      };
      //reset selected site section
      if (state.selectedSiteSection.id) {
        state.selectedSiteSection = {
          ...initialState.selectedSiteSection
        };
      }

      state.siteOperationTempData = {
        ...state.siteOperationTempData,
        selectedEnvironmentsList:
          state.siteOperationTempData.selectedEnvironmentsList,
        generalSiteInformation: generalSiteInformation,
        siteConfigurationsByMenuType: siteConfigurationsByMenuType,
        requiredSiteFields: pendingChanges,
        allSiteConfigurationsByConfigurationId,
        isSiteOperationPending: false,
        requiredSiteConfigurationItems,
        hasRequiredSiteDataReady: true
      };
      //populate correctly pending changes list
      pendingChanges.forEach((pendingChange: PendingChangeType) => {
        const pendingConfigurationItemId = getPendingChangeConfigurationId(
          pendingChange.configurationItemId,
          pendingChange.sectionId
        );
        state.pendingChangesList[pendingConfigurationItemId] = pendingChange;
      });
    },
    setCurrentlyUsedEnvironmentIds(state, action: PayloadAction<string[]>) {
      state.currentlyUsedEnvironmentIds = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(navigateToRecentSite.type, (state) => {
      state.currentOpenSiteView = "SiteDetails";
    });
    builder.addCase(resetUserState.type, (state) => {
      state.savedPendingSiteEdits = {};
    });
    builder.addCase(toggleSelectedDrawer, (state, action) => {
      const { isDrawerOpenValue, drawerType } = action.payload;

      if (state.currentlyUsedEnvironmentIds.length) {
        state.currentlyUsedEnvironmentIds = [];
      }
      if (drawerType === "client") {
        state.currentOpenSiteView = "HomePage";
      } else if (isDrawerOpenValue || drawerType === "site") {
        state.currentOpenSiteView = "";
      }
    });
    builder.addCase(onDrawerOptionBtnClick, (state, action) => {
      const { drawerType, nextDrawerTypeToOpen } = action.payload;

      if (state.currentlyUsedEnvironmentIds.length) {
        state.currentlyUsedEnvironmentIds = [];
      }
      if (nextDrawerTypeToOpen === "client") {
        state.currentOpenSiteView = "HomePage";
      } else if (drawerType === "site" && !nextDrawerTypeToOpen) {
        state.currentOpenSiteView = "SiteDetails";
        state.pendingChangesList = {};
      } else {
        state.currentOpenSiteView = "";
      }
    });
    builder.addCase(resetDrawerStructure.type, (state) => {
      state.currentOpenSiteView = "HomePage";
      state.pendingChangesList = {};
    });
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchFulfilled,
      (state) => {
        state.pendingChangesList = {};
        state.siteOperationTempData = { ...initialState.siteOperationTempData };

        if (state.selectedSiteSection.id) {
          state.selectedSiteSection = {
            ...initialState.selectedSiteSection
          };
        }
      }
    );
    builder.addMatcher(
      siteApi.endpoints.editSiteDetails.matchFulfilled,
      (state) => {
        state.pendingChangesList = {};
        state.siteOperationTempData = { ...initialState.siteOperationTempData };
      }
    );
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchPending,
      (state) => {
        state.siteOperationTempData.isSiteOperationPending = true;
      }
    );
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchRejected,
      (state) => {
        state.siteOperationTempData.isSiteOperationPending = false;
      }
    );
    builder.addMatcher(
      siteApi.endpoints.editSiteDetails.matchFulfilled,
      (state, response) => {
        const siteId = response.meta.arg.originalArgs.siteId;
        state.siteOperationTempData.isSiteOperationPending = false;
        state.pendingChangesList = {};
        state.savedPendingSiteEdits[siteId] = {};
      }
    );
    builder.addMatcher(
      siteApi.endpoints.updateSiteSectionDetails.matchFulfilled,
      (state, response) => {
        const { configurationData, configurationId } =
          response.meta.arg.originalArgs;

        state.selectedSiteSection = {
          id: configurationId,
          ...configurationData
        };
      }
    );
  }
});

export const {
  setSelectedSiteSection,
  updateOpenSiteView,
  updatePendingChangesList,
  deletePendingChangesListEntry,
  setInitialSiteOperationDetails,
  submitRequiredSiteOperationData,
  clearSiteOperationTempData,
  setCurrentlyUsedEnvironmentIds,
  removeSectionForSiteCreation
} = siteStateSlice.actions;

export const siteSliceTransform = createTransform(
  (inboundState: SiteStateType): Partial<SiteStateType> => {
    return {
      savedPendingSiteEdits: inboundState.savedPendingSiteEdits
    };
  },
  (outboundState: Partial<SiteStateType>): SiteStateType => {
    return {
      ...initialState,
      savedPendingSiteEdits: outboundState.savedPendingSiteEdits || {}
    };
  },
  { whitelist: ["site"] }
);
export default siteStateSlice.reducer;
