import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";
import OpenListIcon from "@locaisolutions/icons/dist/icons24px/ArrowDown24Px";
import RefreshIcon from "@locaisolutions/icons/dist/icons20px/Reload20Px";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleIcon from "@locaisolutions/icons/dist/icons24px/CheckmarkCircle24Px";
import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

type DeploymentStatusContentProps = {
  deploymentsByEventType: DeploymentsByEventType;
  deploymentsEventsList: string[];
  isDeploymentEventsDrawerOpen: boolean;
  refetchDeploymentStatus: () => unknown;
  isDeploymentStatusLoading: boolean;
};

const DeploymentEventsListMainTitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: "1.1rem"
}));

const DeploymentEventsStatus = styled(Typography)(() => ({
  textAlign: "center",
  fontWeight: 500,
  wordBreak: "break-word"
}));

const RefetchDeploymentStatusBtn = styled(IconButton)(() => ({
  height: 40,
  width: 40
}));

const DeploymentsDrawerContentMainContainer = styled(Stack)(() => ({
  overflowY: "auto",
  "&::-webkit-scrollbar": { display: "none" },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  flexDirection: "column",
  height: "calc(100% - 20px)",
  gap: "5px"
}));

const DeploymentEventsSublistHeaderContainer = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{ extraProps: { isCurrentDeploymentEventSublistOpen: boolean } }>(
  ({ extraProps, theme }) => ({
    backgroundColor: extraProps.isCurrentDeploymentEventSublistOpen
      ? theme.palette.secondary.main
      : theme.palette.background.paper,
    cursor: "pointer",
    padding: "5px 10px",
    borderRadius: "6px",
    border: `1px solid ${theme.palette.divider}`,
    ...(extraProps.isCurrentDeploymentEventSublistOpen && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    })
  })
);

const DeploymentEventsSublistHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{ extraProps: { shouldRenderBorder: boolean } }>(
  ({ extraProps, theme }) => ({
    display: "grid",
    gridTemplateColumns: "30px minmax(120px, auto) 70px",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "100%",
    padding: `${theme.spacing(1)} 0px`,
    borderBottom: extraProps.shouldRenderBorder
      ? `1px solid ${theme.palette.divider}`
      : "none",
    justifyContent: "space-around"
  })
);

const DeploymentEventsSublistContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{ extraProps: { isLastElement: boolean } }>(({ extraProps, theme }) => ({
  flexDirection: "column",
  gap: theme.spacing(1),
  width: "100%",
  padding: `${theme.spacing(1)} 0px`,
  borderBottom: extraProps.isLastElement
    ? "none"
    : `1px solid ${theme.palette.divider}`
}));
const DeploymentEventsContainer = styled(AccordionDetails, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{ extraProps: { isCurrentDeploymentEventSublistOpen: boolean } }>(
  ({ theme, extraProps }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    border: extraProps.isCurrentDeploymentEventSublistOpen
      ? `1px solid ${theme.palette.divider}`
      : "none",
    borderRadius: "6px",
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  })
);

function DeploymentEventsStatusContent(props: DeploymentStatusContentProps) {
  const {
    deploymentsByEventType,
    deploymentsEventsList,
    isDeploymentEventsDrawerOpen,
    refetchDeploymentStatus,
    isDeploymentStatusLoading
  } = props;
  const [selectedSublistIsOpen, setSelectedSublistIsOpen] =
    useState<string>("");
  const instructions =
    "Please sync the grid and set workstations through Qubit UI if you haven't done so.";
  const { palette } = useTheme();
  useEffect(() => {
    setSelectedSublistIsOpen("");
  }, [
    deploymentsEventsList,
    refetchDeploymentStatus,
    isDeploymentEventsDrawerOpen
  ]);

  const getEventStylingByEventStatus = (
    eventStatus: SiteDeploymentEvent["status"],
    isEventStatusComplete: boolean
  ): { icon: JSX.Element | null; color: string } => {
    if (isEventStatusComplete || eventStatus === "Success")
      return {
        icon: (
          <CheckCircleIcon
            style={{ fill: palette.success.main }}
            aria-label="deployment-event-complete-icon"
          />
        ),
        color: palette.success.dark
      };
    switch (eventStatus) {
      case "Pending":
        return {
          icon: (
            <PendingActionsIcon
              style={{ fill: palette.warning.main }}
              aria-label="deployment-event-complete-icon"
            />
          ),
          color: palette.warning.dark
        };
      case "Error":
        return {
          icon: (
            <ErrorIcon
              style={{ fill: palette.error.main }}
              aria-label="deployment-event-complete-icon"
            />
          ),
          color: palette.error.dark
        };
      default:
        return { icon: null, color: palette.text.primary };
    }
  };

  const handleOpenOrCloseDeploymentStatusSublist = (
    deploymentEventName: string
  ) => {
    if (selectedSublistIsOpen === deploymentEventName)
      setSelectedSublistIsOpen("");
    else {
      setSelectedSublistIsOpen(deploymentEventName);
    }
  };

  return (
    <DeploymentsDrawerContentMainContainer aria-label="deployment-status-drawer-content">
      <Typography aria-label="deployment-status-drawer-instructions">
        Your new site, bins and grid have been initiated!
      </Typography>
      <Typography aria-label="deployment-status-drawer-instructions">
        {instructions}
      </Typography>
      <Stack
        flexDirection="row"
        margin="10px 0px"
        alignItems="center"
        aria-label="deployment-status-drawer-centent-header"
      >
        <DeploymentEventsListMainTitle>
          Deployment Events:
        </DeploymentEventsListMainTitle>
        <Tooltip
          title={"Fetch latest changes"}
          aria-label="refetch-deployment-status-btn"
          placement="top"
        >
          <Box>
            <RefetchDeploymentStatusBtn
              disabled={isDeploymentStatusLoading}
              onClick={() => {
                void refetchDeploymentStatus();
              }}
            >
              <RefreshIcon
                style={{
                  opacity: isDeploymentStatusLoading ? 0.5 : 1,
                  color: "#000"
                }}
              />
            </RefetchDeploymentStatusBtn>
          </Box>
        </Tooltip>
      </Stack>
      <Box>
        {deploymentsEventsList && deploymentsEventsList?.length ? (
          deploymentsEventsList.map((deploymentEventName, index) => {
            const { deployments } = deploymentsByEventType[deploymentEventName];
            const isCurrentDeploymentEventSublistOpen =
              selectedSublistIsOpen === deploymentEventName;
            const isLastElement = index === deploymentsEventsList.length - 1;
            return (
              <Accordion
                expanded={isCurrentDeploymentEventSublistOpen}
                key={`deployment-event-${index}`}
                sx={{
                  p: 0,
                  mb: isLastElement ? 0 : 1,
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  border: "none",
                  "&:before": { display: "none" }
                }}
                disableGutters
                aria-label="deployment sublist panel"
              >
                <DeploymentEventsSublistHeaderContainer
                  extraProps={{ isCurrentDeploymentEventSublistOpen }}
                  onClick={() =>
                    handleOpenOrCloseDeploymentStatusSublist(
                      deploymentEventName
                    )
                  }
                  role="button"
                  expandIcon={
                    <OpenListIcon
                      aria-label="open content arrow"
                      width={15}
                      height={15}
                    />
                  }
                >
                  {deploymentEventName}
                </DeploymentEventsSublistHeaderContainer>
                <DeploymentEventsContainer
                  aria-label="sublist content"
                  extraProps={{ isCurrentDeploymentEventSublistOpen }}
                >
                  <Stack flexDirection="column" gap={1}>
                    {deployments.map((deployment, index) => {
                      const {
                        description,
                        status,
                        deploymentId,
                        completed,
                        errorMsg
                      } = deployment;
                      const { icon, color: textColor } =
                        getEventStylingByEventStatus(status, completed);
                      const isLastElement = deployments.length - 1 === index;
                      const shouldRenderErrorMsg =
                        !!errorMsg && status === "Error";
                      return (
                        <DeploymentEventsSublistContainer
                          key={`deployment-${deploymentId}`}
                          aria-label="deployment-event-details"
                          extraProps={{ isLastElement }}
                        >
                          <DeploymentEventsSublistHeader
                            extraProps={{
                              shouldRenderBorder: shouldRenderErrorMsg
                            }}
                            sx={{
                              gridTemplateColumns: !icon
                                ? "minmax(120px, auto) 100px"
                                : "30px minmax(120px, auto) 70px"
                            }}
                          >
                            {icon && <Icon sx={{ ml: 1 }}>{icon}</Icon>}
                            <Typography
                              aria-label="deployment-event-title"
                              textAlign="center"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {description}
                            </Typography>
                            <DeploymentEventsStatus
                              aria-label="deployment-event-status"
                              sx={{ color: textColor }}
                            >
                              {status}
                            </DeploymentEventsStatus>
                          </DeploymentEventsSublistHeader>
                          {shouldRenderErrorMsg && (
                            <Box sx={{ margin: "0px 5px" }}>
                              <Typography
                                sx={{ color: "error.main" }}
                                variant="subtitle1"
                              >
                                Error details:
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={{ wordBreak: "break-word", ml: 0.5 }}
                              >
                                {errorMsg}
                              </Typography>
                            </Box>
                          )}
                        </DeploymentEventsSublistContainer>
                      );
                    })}
                  </Stack>
                </DeploymentEventsContainer>
              </Accordion>
            );
          })
        ) : (
          <Typography>No deployment status events found.</Typography>
        )}
      </Box>
    </DeploymentsDrawerContentMainContainer>
  );
}

export default DeploymentEventsStatusContent;
