import { ItemInputProps } from "./ItemInput";
import SelectInput from "./SelectInput";

type ToggleSelectProps = {
  value: string;
} & ItemInputProps;

const toggleSelectValues = {
  true: "Enabled",
  false: "Disabled",
  Enabled: "true",
  Disabled: "false"
};

const ToggleSelect = (props: ToggleSelectProps) => {
  const { onInputChange, value } = props;
  return (
    <SelectInput
      {...props}
      selectValues={["Enabled", "Disabled"]}
      value={toggleSelectValues[value as "true" | "false"]}
      onInputChange={(value) => {
        if (onInputChange) {
          onInputChange(toggleSelectValues[value as "Enabled" | "Disabled"]);
        }
      }}
    />
  );
};
export default ToggleSelect;
