import { chipClasses, Components, Theme } from "@mui/material";
import CloseIcon from "@locaisolutions/icons/dist/icons20px/Close20Px";
import { mainColours, EnvironmentColors } from "./colors";

export const muiChip: Components<Omit<Theme, "components">> = {
  MuiChip: {
    defaultProps: {
      deleteIcon: <CloseIcon height={10} width={10} />,
      color: "secondary"
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          borderRadius: "999px",
          borderWidth: "2px",
          cursor: "pointer",
          color: theme.palette.text.primary,
          [`& .${chipClasses.deleteIcon}`]: {
            fill: theme.palette.text.primary,
            borderRadius: "999px",
            margin: "10px 10px 10px 0px",
            ...(ownerState.disabled && {
              fill: mainColours.icon.disabled
            })
          },
          [`& .${chipClasses.deleteIcon}:hover`]: {
            fill: theme.palette.text.primary,
            backgroundColor: mainColours.background.hover
          },
          ...(ownerState.disabled && {
            color: theme.palette.text.disabled
          }),
          ...(ownerState["aria-readonly"] && {
            cursor: "default",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main
            }
          }),
          ...(ownerState.color &&
            ["staging", "qa", "uat", "production"].includes(
              ownerState.color
            ) && {
              ...(ownerState["aria-readonly"] && {
                "&:hover": {
                  backgroundColor:
                    mainColours.environments[
                      ownerState.color as EnvironmentColors
                    ].main
                }
              }),
              ...(ownerState.variant === "outlined" && {
                backgroundColor: theme.palette.background.paper
              }),
              ...(ownerState.disabled &&
                ownerState.variant === "outlined" && {
                  backgroundColor: mainColours.background.disabled
                })
            })
        };
      },
      sizeMedium: ({ theme }) => ({
        fontSize: theme.typography.pxToRem(14)
      })
    }
  }
};
