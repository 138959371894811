import Header from "../site/Header";
import { MainContainer } from "../site/MainSiteContainer";
import AuditLogTable from "./AuditLogTable";

const AuditLog = () => {
  const columnHeaderName = [
    "Configuration Item Name",
    "Original Value",
    "Current Value",
    "Environment(s)",
    "Date",
    "User",
    "Configuration Grouping"
  ];

  return (
    <MainContainer
      sx={{ overflow: "none" }}
      aria-label="site audit log container"
    >
      <Header shouldRenderPendingChanges={false} />
      <AuditLogTable columnHeaderNames={columnHeaderName} rowsData={[]} />
    </MainContainer>
  );
};

export default AuditLog;
