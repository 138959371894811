import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions
} from "@mui/material/styles";

import { muiChip } from "./muiChip";
import { muiSelect } from "./muiSelect";
import { muiOutlineInput } from "./muiOutlineInput";
import { muiButton } from "./muiButton";
import { muiListItemButton } from "./muiListItemButton";
import { muiAppBar } from "./muiAppBar";
import { muiLink } from "./muiLink";
import { muiListItemText } from "./muiListItemText";
import { muiSwitch } from "./muiSwitch";
import { muiCheckBox } from "./muiCheckBox";
import { basicColours, mainColours } from "./colors";
import { muiMenu } from "./muiMenu";

declare module "@mui/material/styles" {
  interface Palette {
    staging: PaletteColor;
    qa: PaletteColor;
    uat: PaletteColor;
    production: PaletteColor;
  }
  interface PaletteOptions {
    staging: PaletteColorOptions;
    qa: PaletteColorOptions;
    uat: PaletteColorOptions;
    production: PaletteColorOptions;
  }
}
declare module "@mui/material/Chip" {
  interface ChipOwnProps {
    disableRipple?: boolean;
    disableElevation?: boolean;
  }
  interface ChipPropsColorOverrides {
    staging: true;
    qa: true;
    uat: true;
    production: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    outlined: false;
    serious: true;
    subtle: true;
  }
}

const themeObj: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: basicColours.blue.main,
      dark: basicColours.blue[600],
      light: basicColours.blue[50],
      contrastText: basicColours.mono.white
    },
    secondary: {
      main: mainColours.background.secondary,
      dark: mainColours.background.hover,
      contrastText: mainColours.text.primary
    },
    text: {
      primary: mainColours.text.primary,
      secondary: mainColours.text.secondary,
      disabled: mainColours.text.disabled
    },
    error: {
      main: mainColours.background.error.primary,
      dark: mainColours.background.error.hover,
      light: basicColours.red[50]
    },
    info: {
      main: mainColours.background.accent.primary,
      dark: mainColours.background.accent.hover,
      light: basicColours.blue[50]
    },
    warning: {
      main: mainColours.background.warning.primary,
      dark: mainColours.background.warning.hover,
      light: basicColours.yellow[100]
    },
    success: {
      main: mainColours.background.success.primary,
      dark: mainColours.background.success.hover,
      light: basicColours.green[50]
    },
    background: {
      default: basicColours.mono.white,
      paper: basicColours.mono.white
    },
    divider: mainColours.border.primary,
    staging: {
      main: mainColours.environments.staging.main,
      dark: mainColours.environments.staging.hover,
      contrastText: basicColours.mono.black,
      light: mainColours.environments.staging.light
    },
    qa: {
      main: mainColours.environments.qa.main,
      dark: mainColours.environments.qa.hover,
      contrastText: basicColours.mono.black,
      light: mainColours.environments.qa.light
    },
    uat: {
      main: mainColours.environments.uat.main,
      dark: mainColours.environments.uat.hover,
      contrastText: basicColours.mono.black,
      light: mainColours.environments.uat.light
    },
    production: {
      main: mainColours.environments.production.main,
      dark: mainColours.environments.production.hover,
      contrastText: basicColours.mono.black,
      light: mainColours.environments.production.light
    }
  },
  shape: {
    borderRadius: 6
  },
  typography: {
    fontFamily: 'CircularXX, "Helvetica Neue", Arial, Helvetica, sans-serif',
    allVariants: {
      color: mainColours.text.primary
    }
  },
  components: {
    ...muiChip,
    ...muiSelect,
    ...muiOutlineInput,
    ...muiButton,
    ...muiListItemButton,
    ...muiAppBar,
    ...muiLink,
    ...muiListItemText,
    ...muiSwitch,
    ...muiCheckBox,
    ...muiMenu,
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          "&.Mui-disabled": {
            pointerEvents: "unset",
            cursor: "not-allowed",
            opacity: 0.5
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: "outlined"
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          padding: "5px 10px"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: mainColours.background.secondary,
          color: mainColours.text.primary,
          fontWeight: 400
        }
      }
    }
  }
};

export const mobileWidth = `(max-width: 600px)`;

export const drawerWidth = 216;
export const navBarHeight = 64;

export const tabletWidth = "(max-width: 1300px)";
export const desktopWidth = "(max-width: 1800px)";
export const tabletPortrait = `(max-width: 800px)`;

export const mainTheme = createTheme(themeObj);
