import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import RequiredConfigurationsItems from "../siteOperations/RequiredConfigurationsItems";
import RequieredDuplicateSiteInformation from "./RequieredDuplicateSiteInformation";
import { getSiteOperationTempData } from "../../redux/selectors/siteSelectors";
import { LoadingSpinner } from "../site/ConfigurationItems";
import { useGetDuplicateSiteDetailsQuery } from "../../redux/api/site";
import { submitRequiredSiteOperationData } from "../site/siteSlice";
import { useAppDispatch } from "../../store";
import { basicColours } from "../../lib/colors";
import ErrorPanel from "../ErrorPanel";
import { skipToken } from "@reduxjs/toolkit/query";
import { emptySiteId } from "../../lib/siteHelpers";

export const ModalContentContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 1200,
  minWidth: 600,
  height: "90%",
  background: theme.palette.background.paper,
  borderRadius: "6px",
  overflow: "hidden",
  "@media (max-width: 1200px)": {
    width: "80%",
    minWidth: "auto"
  },
  "@media (max-width: 900px)": {
    width: "95%",
    maxWidth: "none"
  },
  "@media (max-width: 600px)": {
    width: "98%"
  }
}));

export const ModalHeaderContainer = styled(Box)(({ theme }) => ({
  minHeight: 65,
  background: theme.palette.primary.main,
  color: basicColours.mono.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopRightRadius: "6px",
  borderTopLeftRadius: "6px",
  padding: "10px 20px"
}));

const ContentContainer = styled(Box)(() => ({
  display: "flex",
  height: "90%",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
}));

export const ModalMainContent = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "1fr 40px",
  gap: theme.spacing(2),
  height: "100%",
  width: "98%",
  padding: `${theme.spacing(2)} 0px`,
  overflow: "hidden"
}));

type DuplicateSiteModalProps = {
  isModalOpen: boolean;
  handleModalClose: () => void;
};

const DuplicateSiteModal = (props: DuplicateSiteModalProps) => {
  const { isModalOpen, handleModalClose } = props;
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const dispatch = useAppDispatch();

  const { selectedEnvironmentsList } = siteOperationTempData;

  const siteToDuplicateFrom = selectedDrawersData.site;
  const { name: siteName, id: siteId } = siteToDuplicateFrom || {};
  const hasSelectedEnvironmentsList = !!selectedEnvironmentsList.length;

  const clientName = selectedDrawersData.client?.name || "";

  const modalHeaderTitle = siteName
    ? `Duplicate ${siteName} Configuration`
    : "";

  const { data, isLoading, isError, refetch } = useGetDuplicateSiteDetailsQuery(
    siteId &&
      siteId !== emptySiteId &&
      selectedEnvironmentsList.length &&
      isModalOpen
      ? {
          siteId,
          envIdsList: selectedEnvironmentsList
        }
      : skipToken
  );

  return (
    <Modal
      open={isModalOpen}
      onClose={handleModalClose}
      aria-labelledby="duplicate-site-modal-title"
      sx={{
        height: !hasSelectedEnvironmentsList ? "400px" : "95%",
        top: !hasSelectedEnvironmentsList ? "20%" : "0%"
      }}
      role="dialog"
    >
      <ModalContentContainer
        sx={{
          width: !hasSelectedEnvironmentsList ? "30%" : "70%"
        }}
      >
        <ModalHeaderContainer>
          <Typography
            id="duplicate-site-modal-title"
            fontSize={"1.5rem"}
            fontWeight={500}
            sx={{
              textAlign: "center",
              color: "white"
            }}
          >
            {modalHeaderTitle}
          </Typography>
        </ModalHeaderContainer>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ContentContainer
            sx={{
              justifyContent: isError ? "flex-start" : "center"
            }}
          >
            {isError && (
              <ErrorPanel
                errorMessage="Failed to retrieve site information for duplication."
                sx={{ minWidth: "80%", mt: 7 }}
                onRetryCb={async () => {
                  await refetch();
                }}
              />
            )}

            {!hasSelectedEnvironmentsList ? (
              <RequieredDuplicateSiteInformation
                handleModalClose={handleModalClose}
              />
            ) : (
              <RequiredConfigurationsItems
                siteOperationData={data as SiteConfigurationOperationResponse}
                clientName={clientName}
                handleModalClose={handleModalClose}
                submitBtnName="Duplicate"
                onSubmitRequiredFieldChanges={(pendingChanges, newSiteData) => {
                  dispatch(
                    submitRequiredSiteOperationData({
                      pendingChanges,
                      addionalNewSiteInfo: newSiteData
                    })
                  );
                }}
              />
            )}
          </ContentContainer>
        )}
      </ModalContentContainer>
    </Modal>
  );
};

export default DuplicateSiteModal;
