import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecentSearchType } from "../home/homepageSlice";
import {
  clearSiteOperationTempData,
  submitRequiredSiteOperationData
} from "../site/siteSlice";
import siteApi from "../../redux/api/site";

export type SelectedSingleDrawerDataType = {
  name: string;
  id: string;
  salesforceId: string | null;
};
export type SelectedDrawersDataType = {
  [drawerType in DrawerType]: SelectedSingleDrawerDataType | null;
};
export type UIStateType = {
  drawerState: DrawerState;
  currentSelectedOpenDrawerType: DrawerType | null;
  selectedDrawersData: SelectedDrawersDataType;
};

export type ToggleSelectedDrawerType = {
  drawerType: DrawerType;
  isDrawerOpenValue: boolean;
};

export type OnDrawerMenuBtnClickType = {
  drawerType: DrawerType;
  selectedDrawerOption: SelectedSingleDrawerDataType;
  nextDrawerTypeToOpen: DrawerType | null;
};

const initialState: UIStateType = {
  drawerState: {
    partner: {
      isOpen: false,
      shouldDisplay: false,
      isDisabled: true
    },
    client: {
      isOpen: false,
      shouldDisplay: true,
      isDisabled: false
    },
    site: {
      isOpen: false,
      shouldDisplay: true,
      isDisabled: true
    }
  },
  currentSelectedOpenDrawerType: null,
  selectedDrawersData: {
    partner: null,
    client: null,
    site: null
  }
};

const mainCustomDrawerStateSlice = createSlice({
  name: "mainCustomDrawer",
  initialState: initialState,
  reducers: {
    toggleSelectedDrawer(
      state,
      action: PayloadAction<ToggleSelectedDrawerType>
    ) {
      const { drawerType, isDrawerOpenValue } = action.payload;

      state.drawerState[drawerType].isOpen = isDrawerOpenValue;

      if (isDrawerOpenValue) {
        state.currentSelectedOpenDrawerType = drawerType;
        state.selectedDrawersData[drawerType] = null;
        state.drawerState[drawerType].isDisabled = false;
      }
      if (drawerType === "partner") {
        state.selectedDrawersData.client = null;
        state.drawerState.client.isDisabled = true;
        state.selectedDrawersData.site = null;
        state.drawerState.site.isDisabled = true;
      } else if (drawerType === "client") {
        state.selectedDrawersData.site = null;
        state.drawerState.site.isDisabled = true;
      }
    },
    onDrawerOptionBtnClick(
      state,
      action: PayloadAction<OnDrawerMenuBtnClickType>
    ) {
      const { drawerType, selectedDrawerOption, nextDrawerTypeToOpen } =
        action.payload;

      if (nextDrawerTypeToOpen) {
        state.currentSelectedOpenDrawerType = nextDrawerTypeToOpen;
        state.drawerState[drawerType].isOpen = false;
        state.drawerState[nextDrawerTypeToOpen].isOpen = true;
        state.drawerState[nextDrawerTypeToOpen].isDisabled = false;
      } else {
        state.currentSelectedOpenDrawerType = null;
        state.drawerState[drawerType].isOpen = false;
        //site will always be last drawer - need to make sure site is always accessible
        state.drawerState[drawerType].isDisabled = false;
      }
      state.selectedDrawersData[drawerType] = selectedDrawerOption;
    },
    navigateToRecentSite(state, action: PayloadAction<RecentSearchType>) {
      const { siteName, siteId, clientName, clientId } = action.payload;
      state.drawerState.site.isDisabled = false;
      state.drawerState.site.isOpen = false;
      state.drawerState.client.isOpen = false;
      state.currentSelectedOpenDrawerType = null;
      state.selectedDrawersData.site = {
        name: siteName,
        id: siteId,
        salesforceId: ""
      };

      state.selectedDrawersData.client = {
        name: clientName,
        id: clientId,
        salesforceId: ""
      };
    },
    resetDrawerStructure(state) {
      state.drawerState = {
        partner: {
          isOpen: false,
          shouldDisplay: false,
          isDisabled: true
        },
        client: {
          isOpen: false,
          shouldDisplay: true,
          isDisabled: false
        },
        site: {
          isOpen: false,
          shouldDisplay: true,
          isDisabled: true
        }
      };
      state.currentSelectedOpenDrawerType = null;
      state.selectedDrawersData = {
        partner: null,
        client: null,
        site: null
      };
      state.currentSelectedOpenDrawerType = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submitRequiredSiteOperationData, (state, action) => {
      const { addionalNewSiteInfo, pendingChanges } = action.payload;

      const potentialNewSiteName = pendingChanges.find(
        (el) => el.configurationName.toLowerCase() === "name"
      )?.currentConfigurationItemValues[0]?.configurationValue;

      if (
        potentialNewSiteName &&
        potentialNewSiteName !== state.selectedDrawersData.site?.name
      ) {
        state.selectedDrawersData.site = {
          name: potentialNewSiteName as string,
          id: addionalNewSiteInfo.siteId,
          salesforceId: addionalNewSiteInfo.salesforceId
        };
      }
    });
    builder.addCase(clearSiteOperationTempData, (state, action) => {
      if (!action.payload.shouldHardReset) return;

      state.currentSelectedOpenDrawerType = "site";
      state.drawerState.site = {
        ...state.drawerState.site,
        isOpen: true
      };
      state.selectedDrawersData.site = null;
    });
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchFulfilled,
      (state, action: PayloadAction<SiteCreationResponse>) => {
        const { site } = action.payload;
        state.selectedDrawersData.site = {
          name: site?.name || "",
          id: site?.siteId || "",
          salesforceId: site?.salesforceId || ""
        };
      }
    );
  }
});

export const {
  toggleSelectedDrawer,
  onDrawerOptionBtnClick,
  navigateToRecentSite,
  resetDrawerStructure
} = mainCustomDrawerStateSlice.actions;
export default mainCustomDrawerStateSlice.reducer;
