import { Chip, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { mainColours } from "../../lib/colors";
import { getEnvTagNameToDisplay } from "../../lib/siteHelpers";

export const EnvironmentTagsContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "baseline",
  gap: "5px"
}));

const EnvironmentTag = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    isPartOfPendingChangesList: boolean;
  };
}>(({ extraProps, theme }) => ({
  display: "flex",
  padding: theme.spacing(0.5),
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.primary,
  margin: "5px 5px 8px 0px",
  opacity: extraProps.isPartOfPendingChangesList ? 0.5 : 1,
  maxWidth: "65px"
}));

const EnvironmentTagButton = (props: {
  envName: EnvTagNamesType;
  isPartOfPendingChangesList?: boolean;
  disabled?: boolean;
  selected?: boolean;
  readonly?: boolean;
  onEnvTagSelection?: () => void;
  envKey?: string;
}) => {
  const {
    envName = "Staging",
    isPartOfPendingChangesList,
    onEnvTagSelection,
    selected = true,
    readonly = false,
    envKey
  } = props;
  const envTagName =
    envName.toLowerCase() as keyof typeof mainColours.environments;

  const envTagNameToDisplay = getEnvTagNameToDisplay(envName);
  return (
    <EnvironmentTag
      key={envKey ?? `env-tag-button-${envTagName}`}
      aria-label={`${envTagName}-environment-label`}
      variant={selected ? "filled" : "outlined"}
      extraProps={{
        isPartOfPendingChangesList: isPartOfPendingChangesList || false
      }}
      onClick={() => {
        if (onEnvTagSelection) onEnvTagSelection();
      }}
      disabled={props.disabled}
      disableRipple={readonly}
      aria-readonly={readonly}
      color={envTagName}
      label={envTagNameToDisplay}
    />
  );
};

export default EnvironmentTagButton;
