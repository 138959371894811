import { createSelector } from "reselect";
import { RootState } from "../../store";
import { getSelectedDrawersData } from "./drawerSelectors";

export const getSelectedSiteSection = (state: RootState) =>
  state.site.selectedSiteSection;

export const getCurrentOpenSiteView = (state: RootState) =>
  state.site.currentOpenSiteView;

export const getCurrentlyUsedEnvironmentsForSelectedSite = (state: RootState) =>
  state.site.currentlyUsedEnvironmentIds;

export const getHasRequiredSiteOperationData = (state: RootState) =>
  state.site.siteOperationTempData.hasRequiredSiteDataReady;

export const getSavedPendingSiteEditsBySite = (
  state: RootState
): { [x: string]: PendingChangesListType } => state.site.savedPendingSiteEdits;

export const getCurrentSitePendingConfigurationItemsList = (
  state: RootState
): PendingChangesListType => state.site.pendingChangesList;

export const getPendingConfigurationItemsList = createSelector(
  [
    getSavedPendingSiteEditsBySite,
    getCurrentSitePendingConfigurationItemsList,
    getSelectedDrawersData
  ],
  (
    savedPendingSiteEdits,
    currentSitePendingConfigurations,
    selectedDrawersData
  ) => {
    const siteId = selectedDrawersData.site?.id || "";
    const possibleSavedConfigurationsEdits =
      savedPendingSiteEdits && savedPendingSiteEdits?.[siteId];

    if (
      !Object.keys(currentSitePendingConfigurations).length &&
      possibleSavedConfigurationsEdits
    ) {
      return possibleSavedConfigurationsEdits;
    }
    return currentSitePendingConfigurations;
  }
);

export const getSiteOperationTempData = (state: RootState) =>
  state.site.siteOperationTempData;

export const selectArePendingChangesAvailable = createSelector(
  [getPendingConfigurationItemsList],
  (pendingConfigurationChangesList) =>
    !!Object.keys(pendingConfigurationChangesList).length
);
