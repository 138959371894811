import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { EnvironmentTagsContainer } from "./EnvironmentTagButton";
import ItemInput from "../input/ItemInput";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { Stack } from "@mui/material";
import EnvironmentTagButton from "./EnvironmentTagButton";

type PendingConfigurationItemValuesChangeType = {
  pendingConfigurationIteValuesListChanges: ConfigurationValue[];
  configurationName: string;
  type: "original" | "new";
  dataType: ConfigurationItemDataType;
  validationRules: ValidationRuleType[];
};

const MainContainer = styled(Stack)(() => ({
  flexDirection: "column",
  alignItems: "center",
  marginTop: "5px"
}));

function PendingConfigurationItemValuesChange(
  props: PendingConfigurationItemValuesChangeType
) {
  const {
    pendingConfigurationIteValuesListChanges,
    configurationName,
    type,
    dataType,
    validationRules
  } = props;
  const isOriginalvalue = type === "original";

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(validationRules, environmentsDetailsById);

  return (
    <Box>
      {pendingConfigurationIteValuesListChanges.map((configItem, index) => {
        const { environmentIds, configurationValue } = configItem;
        const currentValidationRules =
          validationRulesPerEnvironment[environmentIds[0]];
        const validationRule = currentValidationRules?.validationRules || null;
        const isRequired = currentValidationRules?.isRequired || false;

        return (
          <MainContainer
            key={`${type}-config-item-value-${configurationName}${index}`}
          >
            <ItemInput
              configurationValue={configurationValue}
              dataType={dataType}
              isDisabled={isOriginalvalue}
              readOnly={!isOriginalvalue}
              required={isRequired}
              validationRules={validationRule}
            />
            <EnvironmentTagsContainer
              key={`${type}-environments-container-${configurationName}${index}`}
              data-testid="environments-checkbox-list"
              sx={{
                alignSelf: "center",
                mt: 1,
                justifyContent: "center"
              }}
            >
              {allAvailableEnvironmentNames.map((envName, i) => {
                if (
                  environmentIds.find(
                    (envId: string) =>
                      envName ===
                      environmentsDetailsById?.[envId]?.environmentName
                  )
                )
                  return (
                    <EnvironmentTagButton
                      key={`${type}-pending-change-${configurationName}-${envName}${i}`}
                      envName={envName}
                      selected
                    />
                  );
                return (
                  <div
                    key={`${type}-pending-change-${configurationName}-${envName}${i}`}
                  />
                );
              })}
            </EnvironmentTagsContainer>
          </MainContainer>
        );
      })}
    </Box>
  );
}

export default PendingConfigurationItemValuesChange;
