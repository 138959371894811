import { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";

import { ConfirmActionDialogContext } from "../useConfirmActionDialog";
import CustomButton from "../CustomButton";

const ConfirmActionDialog = () => {
  const dialogContext = useContext(ConfirmActionDialogContext);
  const { isOpen, title, message, setIsOpen, actionButtonsDetails } =
    dialogContext;

  if (!isOpen) return <></>;
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      role="dialog"
    >
      <Stack
        gap={2}
        sx={{
          minWidth: 550,
          px: 1,
          py: 1,
          "@media (max-width: 900px)": {
            width: "80%",
            maxWidth: "none",
            minWidth: "auto"
          },
          "@media (max-width: 600px)": {
            width: "95%"
          }
        }}
      >
        {typeof title === "string" ? (
          <DialogTitle
            textAlign="center"
            variant="h5"
            fontWeight={500}
            sx={{
              color: "inherit"
            }}
          >
            {title}
          </DialogTitle>
        ) : (
          title
        )}

        <DialogContent aria-label="action dialog content">
          {typeof message === "string" ? (
            <DialogContentText
              sx={{
                fontSize: "1.2rem"
              }}
              color="text.secondary"
            >
              {message}
            </DialogContentText>
          ) : (
            message
          )}
        </DialogContent>
        <DialogActions
          sx={{
            gap: 1
          }}
        >
          {actionButtonsDetails.map((buttonDetails) => {
            const { name, icon, variant, actionCb } = buttonDetails;
            return (
              <CustomButton
                onClick={actionCb}
                variant={variant ?? "contained"}
                key={`dialog-button-${name}`}
                startIcon={icon}
                sx={{
                  height: 38,
                  px: 2
                }}
              >
                {name}
              </CustomButton>
            );
          })}
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmActionDialog;
