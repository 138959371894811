import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import SiteConfigurations from "./SiteConfigurations";
import ConfigurationItems from "./ConfigurationItems";
import {
  getHasRequiredSiteOperationData,
  getSelectedSiteSection,
  getSiteOperationTempData,
  selectArePendingChangesAvailable
} from "../../redux/selectors/siteSelectors";
import Header from "./Header";
import PendingChangesList from "./PendingChangesList";
import { clearSiteOperationTempData } from "./siteSlice";
import { useAppDispatch } from "../../store";
import DeploymentEventsStatusDrawer from "../deploymentsStatus/DeploymentEventsStatusDrawer";
import { useGetDeploymentsStatusQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { emptySiteId } from "../../lib/siteHelpers";

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  height: `calc(100% - 110px)`,
  gridTemplateRows: "60px 1fr",
  padding: 10,
  borderRadius: "6px",
  background: theme.palette.secondary.dark,
  margin: 15
}));

const SecondaryContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  border: `1px 0px 0px 0px`,
  borderColor: theme.palette.divider,
  borderRadius: "6px",
  background: theme.palette.background.paper,
  height: "100%",
  overflow: "hidden"
}));

function MainSiteContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedSiteDrawersData = useSelector(getSelectedDrawersData).site;
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const isSiteSectionSelected = !!selectedSiteSection.id;
  const hasPendingChanges = useSelector(selectArePendingChangesAvailable);

  const [isDeploymentEventsDrawerOpen, updateDeploymentsDrawerStatus] =
    useState(false);

  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const isInNewSiteCreatingMode =
    siteOperationTempData.mode === "newSiteCreation" &&
    hasRequiredSiteOperationData;

  const selectedClientDrawerData = useSelector(getSelectedDrawersData).site;
  useGetDeploymentsStatusQuery(
    {
      siteId: selectedClientDrawerData?.id || ""
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedClientDrawerData?.id || hasRequiredSiteOperationData
    }
  );

  useEffect(() => {
    const siteIdFromPath = location.pathname.split("/")?.[2];
    if (
      !selectedSiteDrawersData?.id ||
      (!siteIdFromPath && !selectedSiteDrawersData?.id)
    )
      return;

    if (selectedSiteDrawersData.id === emptySiteId && isInNewSiteCreatingMode) {
      navigate("/site/new", {
        replace: true
      });
    } else if (selectedSiteDrawersData.id !== siteIdFromPath) {
      navigate(`/site/${selectedSiteDrawersData.id}`, {
        replace: true
      });
    }
  }, [selectedSiteDrawersData, isInNewSiteCreatingMode]);

  useEffect(() => {
    return () => {
      dispatch(clearSiteOperationTempData({ shouldHardReset: false }));
    };
  }, []);
  const deploymentStatusDrawerWidth = !isDeploymentEventsDrawerOpen
    ? "51px"
    : "300px";

  return (
    <MainContainer
      sx={{
        ...(hasRequiredSiteOperationData && {
          border: ({ palette }) => `3px solid ${palette.primary.main}`
        })
      }}
      aria-label="site details container"
    >
      <Header shouldRenderPendingChanges={hasPendingChanges} />
      <SecondaryContainer
        sx={{
          gridTemplateColumns: isInNewSiteCreatingMode
            ? "minmax(200px, 250px) minmax(450px, 1fr)"
            : hasPendingChanges
              ? `minmax(200px, 250px) minmax(450px, 1fr) minmax(270px, 300px) minmax(${isDeploymentEventsDrawerOpen ? "270px" : "51px"} , ${deploymentStatusDrawerWidth})`
              : `minmax(200px, 250px) minmax(450px, 1fr) ${deploymentStatusDrawerWidth}`
        }}
      >
        <SiteConfigurations siteOperationTempData={siteOperationTempData} />
        {isSiteSectionSelected && (
          <ConfigurationItems siteOperationTempData={siteOperationTempData} />
        )}
        {hasPendingChanges && (
          <PendingChangesList siteOperationTempData={siteOperationTempData} />
        )}
        {!isInNewSiteCreatingMode && (
          <DeploymentEventsStatusDrawer
            isDeploymentEventsDrawerOpen={isDeploymentEventsDrawerOpen}
            updateDeploymentsDrawerStatus={(isDrawerOpen) => {
              updateDeploymentsDrawerStatus(isDrawerOpen);
            }}
          />
        )}
      </SecondaryContainer>
    </MainContainer>
  );
}

export default MainSiteContainer;
