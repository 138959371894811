import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { checkUserLoggedInStatus } from "./redux/selectors/userSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useEffect } from "react";
import {
  getHasRequiredSiteOperationData,
  getSiteOperationTempData
} from "./redux/selectors/siteSelectors";
import { getSelectedDrawersData } from "./redux/selectors/drawerSelectors";

const PrivateRoute = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedSiteDrawersData = useSelector(getSelectedDrawersData).site;
  const isUserLoggedIn = useSelector(checkUserLoggedInStatus);
  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );

  const isInNewSiteCreatingMode =
    siteOperationTempData.mode === "newSiteCreation" &&
    hasRequiredSiteOperationData;
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isInNewSiteCreatingMode && location.pathname !== "/site/new") {
      navigate("/site/new", { replace: true });
    } else if (
      !isInNewSiteCreatingMode &&
      location.pathname === "/site/new" &&
      !selectedSiteDrawersData?.id
    ) {
      navigate("/", { replace: true });
    }
  }, [
    isInNewSiteCreatingMode,
    location.pathname,
    navigate,
    selectedSiteDrawersData
  ]);

  useEffect(() => {
    //log out user with auth0 if user has log out of the app
    if (!isUserLoggedIn && isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`
        }
      });
    }
  }, [isAuthenticated, isUserLoggedIn]);

  if (!isUserLoggedIn) return <Navigate to="/login" replace />;

  return <>{children}</>;
};

export default PrivateRoute;
