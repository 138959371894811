import React from "react";
import { styled } from "@mui/material/styles";
import { Stack, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { DescriptionTooltip } from "../site/ConfigurationItem";
import ItemInput from "../input/ItemInput";
import { DuplicateSiteRequiredType } from "./RequiredConfigurationsItems";
import usePrevious from "../../hooks/usePrevious";
import { tabletWidth } from "../../lib/theme";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import EnvironmentTagButton, {
  EnvironmentTagsContainer
} from "../site/EnvironmentTagButton";
import { getApproximateEnvTagsContainerWidth } from "../../lib/helpers";

type RequieredSiteFieldsProps = {
  configurationItem: DuplicateSiteRequiredType;
  updateConfigItemCurrentValue: (
    configurationItemId: string,
    currentValue: string | string[] | boolean | number | null,
    currentValueEnvId?: string
  ) => void;
  validationErrors: {
    currentValueErrorMessage: string;
    currentValuesPerEnvValidationErrors: {
      [envId in string]: string;
    };
  };
  setValidationError: (
    errorMessage: string,
    configItemId: string,
    uniquePerEnvironment?: boolean,
    currentValueEnvId?: string
  ) => void;
  selectedEnvironmentsList: string[];
};

const MainContainer = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "250px 30px 1fr",
  gap: theme.spacing(1),
  width: "100%",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "150px 30px 1fr",
    justifyContent: "center"
  }
}));

export const ConfigurationItemName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    uniquePerEnvironment?: boolean;
  };
}>(({ extraProps, theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: theme.palette.text.secondary,
  position: "relative",
  alignSelf: extraProps?.uniquePerEnvironment ? "flex-start" : "center",
  top: extraProps?.uniquePerEnvironment ? "20px" : "0px"
}));

const RequieredSiteFields = (props: RequieredSiteFieldsProps) => {
  const {
    configurationItem,
    updateConfigItemCurrentValue,
    validationErrors,
    setValidationError,
    selectedEnvironmentsList
  } = props;
  const {
    dataType,
    name: configurationName,
    configurationId,
    configurationItemId,
    currentValue,
    uniquePerEnvironment,
    currentValuesPerEnv,
    validationRules,
    description
  } = configurationItem;
  const previousUniquerPerEnvValue = usePrevious(currentValuesPerEnv);

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  React.useEffect(() => {
    if (!uniquePerEnvironment) setValidationError("", configurationItemId);
  }, [currentValue]);

  React.useEffect(() => {
    if (uniquePerEnvironment && currentValuesPerEnv) {
      if (
        Object.values(
          validationErrors.currentValuesPerEnvValidationErrors
        )[0] === "URL values must be unique per environment."
      ) {
        Object.keys(validationErrors.currentValuesPerEnvValidationErrors).map(
          (envId) => {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        );
      } else {
        Object.keys(currentValuesPerEnv).forEach((envId) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const prevValueForEnv = previousUniquerPerEnvValue?.[envId] || "";
          const currentValueForEnv = currentValuesPerEnv?.[envId] || "";
          if (prevValueForEnv !== currentValueForEnv) {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        });
      }
    }
  }, [currentValuesPerEnv]);

  const { validationRulesPerEnvironment } = useValidationRulesPerEnvironment(
    validationRules,
    environmentsDetailsById
  );

  const isTabletWidth = useMediaQuery(tabletWidth);
  const inputWidth = isTabletWidth ? 200 : 250;
  const approximateEnvTagcontainerWidth = getApproximateEnvTagsContainerWidth(
    selectedEnvironmentsList.length
  );

  return (
    <MainContainer
      data-testid="required-configuration-item-row"
      key={`required-configuration-item-row-${configurationId}`}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "500",
          color: "gray"
        }}
        aria-label="required configuration item name"
      >
        {configurationName}
      </Typography>
      {!!description.length && (
        <DescriptionTooltip
          description={description}
          additionalStyling={{ alignSelf: "flex-start", top: "3px" }}
        />
      )}

      <Box
        width="100%"
        key={`required-configuration-item-container-${configurationItemId}`}
      >
        {Object.keys(currentValuesPerEnv).map((currentEnvId: string, index) => {
          if (!uniquePerEnvironment && index >= 1) return;

          const currentValidationRules = validationRulesPerEnvironment[
            currentEnvId
          ]
            ? validationRulesPerEnvironment[currentEnvId]
            : validationRules[0];
          const validationRule =
            currentValidationRules?.validationRules || null;
          const isRequired = currentValidationRules?.isRequired || false;

          const valueToEdit =
            uniquePerEnvironment && !!currentValuesPerEnv
              ? currentValuesPerEnv[currentEnvId]
              : currentValue;
          const validationErrorMessage = uniquePerEnvironment
            ? validationErrors.currentValuesPerEnvValidationErrors[currentEnvId]
            : validationErrors.currentValueErrorMessage;

          return (
            <Stack
              key={`required-configuration-item-${configurationItemId}-${index}`}
              sx={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
                marginBottom:
                  uniquePerEnvironment && selectedEnvironmentsList?.length > 1
                    ? "15px"
                    : "5px",
                gridColumnStart: -3
              }}
            >
              <ItemInput
                configurationValue={valueToEdit}
                dataType={dataType}
                required={isRequired}
                validationRules={validationRule}
                onInputChange={(newConfigItemValue) => {
                  updateConfigItemCurrentValue(
                    configurationItemId,
                    newConfigItemValue,
                    currentEnvId
                  );
                }}
                errorMessage={validationErrorMessage}
                setValidationError={(message) =>
                  setValidationError(
                    message,
                    configurationItemId,
                    uniquePerEnvironment,
                    currentEnvId
                  )
                }
                inputWidth={
                  inputWidth < approximateEnvTagcontainerWidth
                    ? approximateEnvTagcontainerWidth
                    : inputWidth
                }
              />

              <EnvironmentTagsContainer
                data-testid="env-tags-container"
                sx={{
                  width: approximateEnvTagcontainerWidth,
                  "@media (max-width: 1200px)": {
                    width: "auto"
                  }
                }}
                key={`env-label-container-${configurationItemId}-${index}`}
              >
                {selectedEnvironmentsList.map((envId, i) => {
                  const envName =
                    environmentsDetailsById[envId]?.environmentName ||
                    "Staging";

                  if (
                    (uniquePerEnvironment && envId === currentEnvId) ||
                    !uniquePerEnvironment
                  )
                    return (
                      <EnvironmentTagButton
                        key={`required-field-env-label-${envId}-${i}`}
                        envName={envName}
                        selected
                        readonly={true}
                      />
                    );
                  return null;
                })}
              </EnvironmentTagsContainer>
            </Stack>
          );
        })}
      </Box>
    </MainContainer>
  );
};

export default RequieredSiteFields;
