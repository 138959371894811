import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateUUID } from "../../lib/helpers";
import siteApi from "../../redux/api/site";

export type UserMessageTypeSeverityType = "error" | "info";

export type UserMessageType = {
  type: UserMessageTypeSeverityType;
  id: string;
  duration: number;
  message: string;
};

export type MessagePopperStateType = {
  userMessagesList: UserMessageType[];
};

const initialState: MessagePopperStateType = {
  userMessagesList: []
};

const messagePopperSlice = createSlice({
  name: "messagePopper",
  initialState: initialState,
  reducers: {
    updateUserMessagesList(
      state,
      action: PayloadAction<{
        type: UserMessageTypeSeverityType;
        message: string;
        duration?: number;
      }>
    ) {
      const userMessage = action.payload;
      state.userMessagesList.push({
        ...userMessage,
        id: generateUUID(),
        duration: userMessage.duration || 4000
      });
    },
    removeUserMessage(state, action: PayloadAction<string>) {
      const userMessageIdToRemove = action.payload;
      const updatedUserMessagesList = state.userMessagesList.filter(
        (userMessage) => userMessage.id !== userMessageIdToRemove
      );
      state.userMessagesList = updatedUserMessagesList;
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        siteApi.endpoints.postDuplicateOrCreateSite.matchFulfilled,
        (state) => {
          state.userMessagesList.push({
            id: generateUUID(),
            duration: 4000,
            type: "info",
            message: "Changes submitted successfully!"
          });
        }
      )
      .addMatcher(
        siteApi.endpoints.postDuplicateOrCreateSite.matchRejected,
        (state) => {
          state.userMessagesList.push({
            id: generateUUID(),
            duration: 4000,
            type: "error",
            message: "Unable to duplicate/create site!"
          });
        }
      );
    builder.addMatcher(
      siteApi.endpoints.getDeploymentsStatus.matchRejected,
      (state) => {
        state.userMessagesList.push({
          id: generateUUID(),
          duration: 4000,
          type: "error",
          message: "Unable to fetch latest deployment changes!"
        });
      }
    );
  }
});

export const { updateUserMessagesList, removeUserMessage } =
  messagePopperSlice.actions;
export default messagePopperSlice.reducer;
