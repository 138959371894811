import {
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import UndoIcon from "@locaisolutions/icons/dist/icons20px/Undo20Px";
import CustomButton from "../CustomButton";
import {
  ModalContentContainer,
  ModalHeaderContainer
} from "../duplicate/DuplicateSiteModal";
import { basicColours } from "../../lib/colors";
import siteApi, {
  useUpdateSiteSectionDetailsMutation
} from "../../redux/api/site";
import { useAppDispatch } from "../../store";
import { updateUserMessagesList } from "../messagePopper/messagePopperSlice";
import { useSelector } from "react-redux";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { getSelectedSiteSection } from "../../redux/selectors/siteSelectors";

type EditSiteSectionModalProps = {
  onClose: () => void;
  isEditSectionModalOpen: boolean;
};
type EditSiteSectionModalForm = {
  name: string;
  description: string;
  notes: string | null;
};
const EditSiteSectionModal = (props: EditSiteSectionModalProps) => {
  const { isEditSectionModalOpen, onClose } = props;
  const currentSiteSection = useSelector(getSelectedSiteSection);
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteId = selectedDrawersData.site?.id;
  const dispatch = useAppDispatch();
  const [updateSiteSectionDetails] = useUpdateSiteSectionDetailsMutation();
  const form = useForm<EditSiteSectionModalForm>({
    values: {
      name: currentSiteSection.name,
      description: currentSiteSection.description || "",
      notes: currentSiteSection.notes
    },
    mode: "onChange"
  });
  const {
    register,
    formState: { errors, isValid, isDirty },
    reset: resetForm,
    handleSubmit,
    setValue,
    watch
  } = form;

  const onSubmit: SubmitHandler<EditSiteSectionModalForm> = async (data) => {
    try {
      await updateSiteSectionDetails({
        configurationId: currentSiteSection.id,
        configurationData: data
      }).unwrap();

      dispatch(
        siteApi.util.invalidateTags([
          {
            type: "site sections",
            id: siteId
          }
        ])
      );
      dispatch(
        siteApi.util.invalidateTags([
          {
            type: "site configuration items",
            id: currentSiteSection.id
          }
        ])
      );
      handleCloseModal();
      dispatch(
        updateUserMessagesList({
          message: "Site section changes updated successfully.",
          type: "info"
        })
      );
    } catch {
      dispatch(
        updateUserMessagesList({
          message: "Unable to update site section details.",
          type: "error"
        })
      );
    }
  };

  const handleCloseModal = () => {
    onClose();
    resetForm();
  };

  const resetToInitialValueBtn = (
    fieldName: "name" | "description" | "notes"
  ) => {
    const initialFieldValue = currentSiteSection[fieldName];
    const isSameFieldValueAsInitial = watch(fieldName) === initialFieldValue;

    if (!initialFieldValue || isSameFieldValueAsInitial) return <></>;

    return (
      <Tooltip title="Revert to initial value">
        <IconButton
          onClick={() => {
            setValue(fieldName, currentSiteSection[fieldName]);
          }}
        >
          <UndoIcon />
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <Modal
      open={isEditSectionModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="edit-site-section-title"
      sx={{ bottom: "20%" }}
      role="dialog"
    >
      <ModalContentContainer
        sx={{ minHeight: 300, minWidth: 400, width: "auto", height: "auto" }}
      >
        <form
          autoComplete="off"
          onSubmit={(event) => void handleSubmit(onSubmit)(event)}
        >
          <ModalHeaderContainer sx={{ minHeight: 50 }}>
            <Typography
              id="edit-site-section-title"
              variant="h5"
              textAlign="center"
              color={basicColours.mono.white}
            >
              Edit site section details
            </Typography>
          </ModalHeaderContainer>
          <Stack
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              mt: 5,
              mb: 3,
              gap: 5,
              mx: 2
            }}
          >
            <Stack flexDirection="column" gap={4} alignItems="center">
              <TextField
                {...register("name", {
                  required: "Section name is required.",
                  minLength: {
                    value: 4,
                    message: "Minimum required length is 4 characters."
                  }
                })}
                label="Name"
                sx={{ height: 45, width: 320 }}
                required
                error={!!errors.name?.message}
                helperText={errors.name?.message}
                InputProps={{ endAdornment: resetToInitialValueBtn("name") }}
              />
              <TextField
                {...register("description", {
                  required: "Description is required."
                })}
                required
                label="Description"
                multiline
                sx={{ height: "auto", width: 320 }}
                error={!!errors.description?.message}
                helperText={errors.description?.message}
                InputProps={{
                  endAdornment: resetToInitialValueBtn("description")
                }}
              />
              <TextField
                {...register("notes")}
                label="Notes"
                multiline
                sx={{ height: "auto", width: 320 }}
                InputProps={{ endAdornment: resetToInitialValueBtn("notes") }}
              />
            </Stack>
            <Stack flexDirection="row" gap={3}>
              <CustomButton
                sx={{ height: 35 }}
                color="secondary"
                variant="contained"
                onClick={handleCloseModal}
              >
                Close
              </CustomButton>
              <CustomButton
                sx={{ minWidth: 180, height: 35 }}
                disabled={!isValid || !isDirty}
                variant="contained"
                type="submit"
              >
                Submit changes
              </CustomButton>
            </Stack>
          </Stack>
        </form>
      </ModalContentContainer>
    </Modal>
  );
};
export default EditSiteSectionModal;
