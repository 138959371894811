import { Components, Theme } from "@mui/material";
import { mainColours } from "./colors";

export const muiMenu: Components<Omit<Theme, "components">> = {
  MuiMenu: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: theme.spacing(0.5),
        borderRadius: "6px",
        "& .MuiList-root": {
          padding: 0
        }
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          fontSize: 16,
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          color: theme.palette.text.secondary,
          fontWeight: 500,
          "&:hover": {
            backgroundColor: mainColours.background.hover
          },
          "& .MuiSvgIcon-root, svg": {
            fontSize: 20,
            height: 20,
            width: 20,
            color: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
            marginRight: theme.spacing(1.5)
          },
          ...(ownerState.divider && {
            borderColor: theme.palette.divider
          }),
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: mainColours.text.accent,
            "&:hover": {
              backgroundColor: theme.palette.primary.light
            }
          }
        };
      }
    }
  }
};
