import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton, { IconButtonOwnProps } from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import PostAddIcon from "@mui/icons-material/PostAdd";
import CloseIcon from "@locaisolutions/icons/dist/icons24px/Close24Px";
import DuplicateIcon from "@locaisolutions/icons/dist/icons20px/Duplicate20Px";
import AuditLogsIcon from "@locaisolutions/icons/dist/icons20px/FileAslog20Px";

import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { mobileWidth } from "../../lib/theme";

import { clearSiteOperationTempData, updateOpenSiteView } from "./siteSlice";
import {
  getCurrentlyUsedEnvironmentsForSelectedSite,
  getCurrentOpenSiteView,
  getHasRequiredSiteOperationData,
  getPendingConfigurationItemsList,
  getSiteOperationTempData
} from "../../redux/selectors/siteSelectors";

import DuplicateSiteModal from "../duplicate/DuplicateSiteModal";
import { useAppDispatch } from "../../store";
import usePostDuplicateOrCreateSiteHook from "../../hooks/usePostDuplicateOrCreateSiteHook";

import {
  getEnvIdsListFromSelectedConfigurationItems,
  getConfigurationItemsFromPendingChanges
} from "../../lib/siteHelpers";
import siteApi, {
  useEditSiteDetailsMutation,
  useGetSitesListByClientQuery
} from "../../redux/api/site";
import { mainColours } from "../../lib/colors";
import CustomButton from "../CustomButton";
import { Stack } from "@mui/material";
import AddNewEnvironmentModal from "../addNewSiteEnvironment/AddNewEnvironmentModal";
import { updateUserMessagesList } from "../messagePopper/messagePopperSlice";
import { useConfirmActionDialog } from "../useConfirmActionDialog";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import EnvironmentTagButton from "./EnvironmentTagButton";

const HeaderMainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "5px 0px 5px 15px"
}));

const CloseAuditLogPageIcon = styled(CloseIcon)(({ theme }) => ({
  height: 18,
  width: 18,
  position: "relative",
  color: theme.palette.text.primary
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
  height: 40,
  width: 40,
  borderRadius: "6px",
  padding: 10,
  gap: 10,
  backgroundColor: mainColours.button.subtle.main,
  color: theme.palette.text.primary,
  "&:hover": {
    cursor: "pointer",
    backgroundColor: mainColours.button.subtle.hover
  },
  "&:disabled": {
    cursor: "default",
    backgroundColor: mainColours.button.subtle.hover
  }
}));

const SiteActionButton = (
  props: {
    icon: JSX.Element;
    iconName: string;
    onClick: () => void;
    tooltipTitle?: string;
  } & IconButtonOwnProps
) => {
  const { tooltipTitle, icon, iconName, onClick, ...iconButtonOwnProps } =
    props;

  return (
    <Tooltip title={tooltipTitle} key={`button-${iconName}`} disableInteractive>
      <HeaderButton
        onClick={onClick}
        id={`header-button-${iconName}`}
        {...iconButtonOwnProps}
      >
        {icon}
      </HeaderButton>
    </Tooltip>
  );
};

type HeaderPropsType = {
  shouldRenderPendingChanges: boolean;
};

const Header = (props: HeaderPropsType) => {
  const { shouldRenderPendingChanges } = props;
  const [isDuplicateSiteOpen, setIsDuplicateSiteOpen] =
    useState<boolean>(false);
  const [isAddNewEnvironmentModalOpen, updateAddNewEnvironmentModalStatus] =
    useState<boolean>(false);

  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const { data: siteListByClient = [] } = useGetSitesListByClientQuery(
    {
      clientId: selectedDrawersData?.client?.id || ""
    },
    {
      skip: !selectedDrawersData?.client?.id
    }
  );
  const { data: environmentsData } = useGetEnvironmentsListQuery();
  const fullListOfEnvById = environmentsData?.environmentsDetailsById;
  const currentlyUsedEnvironments = useSelector(
    getCurrentlyUsedEnvironmentsForSelectedSite
  );
  const openCurrentSiteView = useSelector(getCurrentOpenSiteView);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const [
    updateSiteConfigurationsItemsDetails,
    { isLoading: submitConfigItemsChangesLoading }
  ] = useEditSiteDetailsMutation();
  const siteOperationTempData = useSelector(getSiteOperationTempData);
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );
  const isInNewSiteCreatingMode =
    siteOperationTempData.mode === "newSiteCreation" &&
    hasRequiredSiteOperationData;
  const isInNewAddedEnvironmentMode =
    siteOperationTempData.mode === "newSiteEnvironment" &&
    hasRequiredSiteOperationData;

  const { openDialog, resetDialogState: closeDialog } =
    useConfirmActionDialog();

  const {
    isFetching: duplicateSiteLoading,
    submitSiteToDuplicateOrCreateChanges
  } = usePostDuplicateOrCreateSiteHook(
    siteOperationTempData,
    pendingConfigurationChangesList,
    selectedDrawersData.client?.id
  );

  const siteName = selectedDrawersData.site?.name || "";
  const isAuditLogOpen = openCurrentSiteView === "AuditLog";
  const title = isAuditLogOpen
    ? `${siteName} Audit Logs`
    : `${siteName} Configuration`;
  const isMobile = useMediaQuery(mobileWidth);
  const dispatch = useAppDispatch();
  const siteViewActions = [
    {
      icon: (
        <DuplicateIcon
          height={30}
          width={30}
          aria-label="duplicate-site-button-icon"
        />
      ),
      name: "Duplicate Site",
      onClickBtn: () => {
        setIsDuplicateSiteOpen(true);
      },
      disabled: !currentlyUsedEnvironments.length
    },
    {
      icon: (
        <PostAddIcon
          sx={{
            height: "28px",
            width: "28px"
          }}
          aria-label="add-environment-button-icon"
        />
      ),
      name: "Add Environment",
      onClickBtn: () => {
        updateAddNewEnvironmentModalStatus(true);
      },
      disabled: !currentlyUsedEnvironments.length
    },
    {
      icon: (
        <AuditLogsIcon
          height={20}
          width={20}
          aria-label="audit-logs-button-icon"
        />
      ),
      name: "Audit Logs",
      onClickBtn: () => {
        dispatch(updateOpenSiteView("AuditLog"));
      }
    }
  ];

  const submitEditChanges = async () => {
    const selectedSiteGeneralSiteInformation = siteListByClient?.find(
      (site) => site.siteId === selectedDrawersData?.site?.id
    );

    if (!selectedSiteGeneralSiteInformation) return;

    try {
      const currentPendingChanges = getConfigurationItemsFromPendingChanges(
        pendingConfigurationChangesList
      );

      let editFields: EditSiteConfigurationItemsBody;
      if (isInNewAddedEnvironmentMode) {
        const pendingChangesConfigItemIds = Object.values(
          pendingConfigurationChangesList
        ).map((el) => el.configurationItemId);
        const newConfigItemsWithUpdatedEnvironments = Object.values(
          siteOperationTempData.allSiteConfigurationsByConfigurationId as AllConfigurationsbyConfigId
        )
          .reduce<ConfigurationItemType[]>((acc, configuration) => {
            const configurationItems = configuration.configurationItems;
            return [...acc, ...configurationItems];
          }, [])
          .filter(
            (configItem) =>
              !pendingChangesConfigItemIds.includes(
                configItem.configurationItemId
              )
          );

        const updatedConfigItemsForNewEnvironment = [
          ...newConfigItemsWithUpdatedEnvironments,
          ...currentPendingChanges
        ];

        editFields = {
          configurationItems: updatedConfigItemsForNewEnvironment,
          canDeleteSite: false,
          environmentIds: siteOperationTempData.selectedEnvironmentsList,
          site: {
            ...selectedSiteGeneralSiteInformation,
            address1: selectedSiteGeneralSiteInformation?.address1
          }
        };
      } else {
        editFields = {
          configurationItems: currentPendingChanges,
          canDeleteSite: false,
          environmentIds: getEnvIdsListFromSelectedConfigurationItems(
            currentPendingChanges
          ),
          site: {
            ...selectedSiteGeneralSiteInformation,
            address1: selectedSiteGeneralSiteInformation?.address1
          }
        };
      }

      await updateSiteConfigurationsItemsDetails({
        editFields,
        siteId: selectedSiteGeneralSiteInformation.siteId
      }).unwrap();

      dispatch(
        siteApi.util.invalidateTags([
          {
            type: "site sections",
            id: selectedSiteGeneralSiteInformation.siteId
          }
        ])
      );
      dispatch(
        updateUserMessagesList({
          type: "info",
          message: isInNewAddedEnvironmentMode
            ? "Added new environment successfully."
            : "Site changes successfully saved."
        })
      );
    } catch (err) {
      dispatch(
        updateUserMessagesList({
          type: "error",
          message: isInNewAddedEnvironmentMode
            ? "Failed adding new environment to current site"
            : "Failed updating current site"
        })
      );
    }
  };
  const selectedEnvironmentsforSiteOperation = useMemo(() => {
    if (
      !siteOperationTempData.mode ||
      !environmentsData?.environmentsDetailsById
    )
      return <></>;
    return (
      <Stack
        flexDirection="row"
        gap={0.5}
        alignItems="center"
        my={0.5}
        alignSelf={"center"}
      >
        {siteOperationTempData.selectedEnvironmentsList.map(
          (envId: string, i) => {
            return (
              <EnvironmentTagButton
                key={`site-operation-selected-env-${i}`}
                envKey={`site-operation-selected-env-${i}`}
                envName={
                  environmentsData.environmentsDetailsById[envId]
                    .environmentName ?? "Staging"
                }
              />
            );
          }
        )}
      </Stack>
    );
  }, [siteOperationTempData, environmentsData]);

  const confirmActionDialogMessage = useMemo(() => {
    const newlyAddedEnvIdForSite =
      fullListOfEnvById && siteOperationTempData.mode === "newSiteEnvironment"
        ? siteOperationTempData.selectedEnvironmentsList.filter(
            (envId) => !currentlyUsedEnvironments.includes(envId)
          )?.[0]
        : "";
    switch (siteOperationTempData.mode) {
      case "newSiteEnvironment":
        return (
          <Stack flexDirection="column" color="text.secondary">
            <Typography sx={{ color: "inherit", fontSize: "1.2rem" }}>
              {"You have added new "}
              <span
                style={{ fontWeight: 600 }}
              >{`${fullListOfEnvById?.[newlyAddedEnvIdForSite]?.environmentName ?? ""} environment `}</span>
              {"to current site."}
              <br />
              {"Configuration changes will be applied to"}
              <span style={{ fontWeight: 600 }}>{" environment(s):"}</span>
            </Typography>
            {selectedEnvironmentsforSiteOperation}
            <Typography
              sx={{
                color: "inherit",
                fontSize: "1.2rem"
              }}
            >
              {"Do you wish to proceed with the current site changes?"}
            </Typography>
          </Stack>
        );
      case "newSiteCreation":
        return (
          <Stack flexDirection="column" color="text.secondary">
            {siteOperationTempData.fromSite ? (
              <Typography
                sx={{
                  color: "inherit",
                  fontSize: "1.2rem",
                  alignSelf: "self-start"
                }}
              >
                {"You are attempting to duplicate site "}
                <span style={{ fontWeight: 600 }}>
                  {` ${siteOperationTempData.fromSite.name} `}
                </span>
                {" to create site"}
                <span style={{ fontWeight: 600 }}>
                  {` ${siteOperationTempData.generalSiteInformation?.displayName ?? ""}`}
                </span>
                {"."}
              </Typography>
            ) : (
              <Typography sx={{ color: "inherit", fontSize: "1.2rem" }}>
                {"You are attempting to create the new site"}
                <span style={{ fontWeight: 500 }}>
                  {` ${siteOperationTempData.generalSiteInformation?.displayName ?? ""}.`}
                </span>
              </Typography>
            )}
            <Typography sx={{ color: "inherit", fontSize: "1.2rem" }}>
              {"New site configurations will be copied to"}
              <span style={{ fontWeight: 600 }}>{" environment(s):"}</span>
            </Typography>
            {selectedEnvironmentsforSiteOperation}
            <Typography
              sx={{
                color: "inherit",
                fontSize: "1.2rem"
              }}
            >
              {`Do you wish to proceed with new site ${siteOperationTempData.fromSite ? "duplication" : "creation"}?`}
            </Typography>
          </Stack>
        );
      default:
        return (
          <Typography sx={{ fontSize: "1.2rem" }} color="text.secondary">
            {"You have "}
            <span
              style={{
                fontWeight: 600
              }}
            >{`${Object.keys(pendingConfigurationChangesList).length} pending site edit(s) `}</span>
            for the current site.
            <br />
            {"Do you wish to submit current the pending changes(s)?"}
          </Typography>
        );
    }
  }, [
    siteOperationTempData.mode,
    pendingConfigurationChangesList,
    siteOperationTempData
  ]);

  const cancelActionCb = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const submitActionCb = useCallback(() => {
    if (siteOperationTempData.mode === "newSiteCreation") {
      submitSiteToDuplicateOrCreateChanges();
    } else {
      void submitEditChanges();
    }
    closeDialog();
  }, [
    siteOperationTempData.mode,
    closeDialog,
    submitSiteToDuplicateOrCreateChanges,
    submitEditChanges
  ]);

  const handleSubmitPendingChanges = () => {
    openDialog({
      title: isInNewAddedEnvironmentMode
        ? "Confirm new environment addition"
        : isInNewSiteCreatingMode
          ? `Confirm new site ${siteOperationTempData.fromSite ? "duplication" : "creation"}`
          : "Confirm pending site edits",
      message: confirmActionDialogMessage,
      actionButtonsDetails: [
        {
          name: "Cancel",
          variant: "subtle",
          actionCb: cancelActionCb
        },
        {
          name: isInNewAddedEnvironmentMode
            ? "Submit new environment changes"
            : isInNewSiteCreatingMode
              ? "Create new site"
              : "Submit pending change(s)",
          actionCb: submitActionCb
        }
      ]
    });
  };
  const onCloseActionBtn = () => {
    if (isAuditLogOpen) {
      dispatch(updateOpenSiteView("SiteDetails"));
    } else if (hasRequiredSiteOperationData) {
      openDialog({
        title: "Unsaved Changes",
        message: (
          <Typography>
            You have unsaved changes.
            <br />
            Are you sure you want to leave without submitting current changes?
          </Typography>
        ),
        actionButtonsDetails: [
          {
            name: "Stay",
            variant: "subtle",
            actionCb: cancelActionCb
          },
          {
            name: "Leave",
            variant: "serious",
            actionCb: () => {
              cancelActionCb();
              dispatch(clearSiteOperationTempData({ shouldHardReset: true }));
            }
          }
        ]
      });
    }
  };
  const siteOperationModeTitle = isInNewAddedEnvironmentMode
    ? "Add new environment"
    : "Duplicate/Create new site";

  return (
    <HeaderMainContainer data-testid="main-header">
      <Stack
        gap={3}
        flexDirection="row"
        alignItems="center"
        mb={0.5}
        flex={1}
        justifyContent={"space-between"}
      >
        <Stack flexDirection="row" gap={2}>
          <Typography
            fontSize={isMobile ? 18 : 28}
            fontWeight="bold"
            data-testid="site-header-title"
          >
            {title}
          </Typography>
          {!isAuditLogOpen && !hasRequiredSiteOperationData && (
            <Stack gap={2} flexDirection="row" alignItems="center">
              {siteViewActions.map((action) => (
                <SiteActionButton
                  icon={action.icon}
                  key={`button-${action.name}`}
                  iconName={`${action.name}-icon`}
                  tooltipTitle={action.name}
                  onClick={action.onClickBtn}
                  disabled={action.disabled ?? false}
                />
              ))}
            </Stack>
          )}
        </Stack>
        <Stack flexDirection="row" gap={1}>
          {hasRequiredSiteOperationData && (
            <Box
              sx={{
                py: 1,
                px: 2,
                borderRadius: "6px",
                mx: 3,
                backgroundColor: mainColours.button.subtle.main
              }}
            >
              <Typography
                color={mainColours.text.primary}
                fontWeight={300}
                fontSize="1rem"
              >
                Mode:
                <span style={{ marginLeft: "5px", fontWeight: "600" }}>
                  {siteOperationModeTitle}
                </span>
              </Typography>
            </Box>
          )}
          {!isAuditLogOpen && shouldRenderPendingChanges && (
            <CustomButton
              sx={{
                minWidth: 180,
                height: 40,
                fontSize: "1rem",
                px: 2
              }}
              variant="contained"
              loading={submitConfigItemsChangesLoading || duplicateSiteLoading}
              onClick={handleSubmitPendingChanges}
            >
              Submit changes
            </CustomButton>
          )}
          {(isAuditLogOpen || hasRequiredSiteOperationData) && (
            <SiteActionButton
              icon={<CloseAuditLogPageIcon />}
              iconName="site-details-logs-icon"
              onClick={onCloseActionBtn}
              tooltipTitle={
                hasRequiredSiteOperationData
                  ? `Close "${siteOperationModeTitle}" mode`
                  : undefined
              }
              sx={{
                ml: 5,
                mr: 0.5
              }}
            />
          )}
        </Stack>
      </Stack>
      {isDuplicateSiteOpen && (
        <DuplicateSiteModal
          isModalOpen={isDuplicateSiteOpen}
          handleModalClose={() => {
            setIsDuplicateSiteOpen(!isDuplicateSiteOpen);
          }}
        />
      )}
      {isAddNewEnvironmentModalOpen && (
        <AddNewEnvironmentModal
          isModalOpen={isAddNewEnvironmentModalOpen}
          onClose={() => {
            updateAddNewEnvironmentModalStatus(false);
          }}
        />
      )}
    </HeaderMainContainer>
  );
};

export default Header;
