import { IconButton, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import ListItemIcon from "@locaisolutions/icons/dist/icons20px/List20Px";
import { useState } from "react";

const AdditionalActionsMenu = (props: {
  actionsList: Array<{
    onActionCb?: () => void;
    name: string;
    icon?: JSX.Element;
    isDisabled?: boolean;
  }>;
  buttonSx?: SxProps<Theme>;
  buttonIcon?: JSX.Element;
  isDisabled?: boolean;
}) => {
  const { actionsList, buttonSx: sx, isDisabled = false, buttonIcon } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClickAdditionalActionBtn = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAdditionalActionsBtn = () => {
    setAnchorEl(null);
  };
  const normalizedSx: SxProps<Theme> = Array.isArray(sx) ? sx : [sx];

  return (
    <>
      <IconButton
        sx={[
          (theme) => ({
            borderRadius: "6px",
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            "&: hover": {
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              borderColor: theme.palette.grey["500"]
            }
          }),
          ...normalizedSx
        ]}
        role="button"
        disabled={isDisabled}
        aria-disabled={isDisabled}
        aria-label="open additional actions menu"
        onClick={handleClickAdditionalActionBtn}
      >
        {buttonIcon || (
          <ListItemIcon
            style={{
              height: 18,
              width: 18
            }}
          />
        )}
      </IconButton>
      <Menu
        aria-label="additional actions menu"
        aria-haspopup
        aria-expanded={isMenuOpen}
        open={isMenuOpen}
        onClose={handleCloseAdditionalActionsBtn}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {actionsList.map((action, index) => (
          <MenuItem
            key={`addional-actions-menu-item-${action.name}`}
            divider={index < actionsList.length - 1}
            onClick={() => {
              if (!action.onActionCb) return;
              action.onActionCb();
              handleCloseAdditionalActionsBtn();
            }}
            disabled={action.isDisabled || !action.onActionCb}
          >
            {action.icon && action.icon}
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AdditionalActionsMenu;
