import { styled } from "@mui/material/styles";
import { Box, SxProps, Theme } from "@mui/material";
import CheckIcon from "@locaisolutions/icons/dist/icons24px/Checkmark24Px";

import CustomButton from "../CustomButton";

const ButtonsContainer = styled(Box)(() => ({
  display: "flex",
  flex: 0.1,
  justifyContent: "space-around",
  width: "100%",
  margin: "auto"
}));

const SiteOperationModalActionButtons = (props: {
  onCancelCb: () => void;
  onSubmitCb: () => void;
  submitBtnName: string;
  hasSelectedEnvironmentsList?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const {
    onCancelCb,
    onSubmitCb,
    submitBtnName,
    hasSelectedEnvironmentsList = false,
    sx
  } = props;
  const normalizedSx: SxProps<Theme> = Array.isArray(sx) ? sx : [sx];
  return (
    <ButtonsContainer sx={[...normalizedSx]}>
      <CustomButton
        sx={{
          minWidth: 180,
          height: 40
        }}
        onClick={onCancelCb}
        variant="subtle"
      >
        Cancel
      </CustomButton>
      <CustomButton
        sx={{
          minWidth: 180,
          height: 40
        }}
        color="primary"
        variant="contained"
        onClick={onSubmitCb}
        endIcon={
          hasSelectedEnvironmentsList ? (
            <CheckIcon
              style={{
                fill: "#fff"
              }}
              data-testid="submit-action-icon"
            />
          ) : undefined
        }
      >
        {submitBtnName}
      </CustomButton>
    </ButtonsContainer>
  );
};

export default SiteOperationModalActionButtons;
