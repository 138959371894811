import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@locaisolutions/icons/dist/icons24px/Export24Px";
import useMediaQuery from "@mui/material/useMediaQuery";

import autostoreLogo from "../assets/images/as-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetDrawerStructure } from "./drawer/mainCustomDrawerSlice";
import { getUserProfile } from "../redux/selectors/userSelectors";
import { resetUserState } from "./login/userSlice";
import { clientConfigApi } from "../redux/api";
import { mobileWidth, navBarHeight } from "../lib/theme";
import { useAppDispatch } from "../store";
import { useConfirmActionDialog } from "./useConfirmActionDialog";
import { getHasRequiredSiteOperationData } from "../redux/selectors/siteSelectors";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hasRequiredSiteOperationData = useSelector(
    getHasRequiredSiteOperationData
  );

  const { openDialog, resetDialogState: closeDialog } =
    useConfirmActionDialog();
  const userProfile = useSelector(getUserProfile);
  const isUserLoggedIn = !!userProfile?.accessToken;

  const handleWarnUserOfUnsavedChanges = (onPageLeaveCb: () => void) => {
    if (hasRequiredSiteOperationData) {
      openDialog({
        title: "Unsaved Changes",
        message: (
          <Typography>
            You have unsaved changes.
            <br />
            Are you sure you want to leave without submitting current changes?
          </Typography>
        ),
        actionButtonsDetails: [
          {
            name: "Stay",
            variant: "subtle",
            actionCb: () => {
              closeDialog();
            }
          },
          {
            name: "Leave",
            variant: "serious",
            actionCb: onPageLeaveCb
          }
        ]
      });
    } else {
      onPageLeaveCb();
    }
  };
  const navigateToHomePage = () => {
    if (isUserLoggedIn) {
      handleWarnUserOfUnsavedChanges(() => {
        dispatch(resetDrawerStructure());
        navigate("/");
      });
    } else {
      navigate("/");
    }
  };

  const logoutUser = () => {
    handleWarnUserOfUnsavedChanges(() => {
      dispatch(resetUserState());
      dispatch(clientConfigApi.util.resetApiState());
    });
  };

  const isMobile = useMediaQuery(mobileWidth);
  return (
    <>
      <AppBar
        position="fixed"
        data-testid="navbar"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: navBarHeight
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <img
            src={autostoreLogo}
            alt="partner logo"
            style={{
              height: isMobile ? 20 : 30,
              background: "transparent",
              marginBottom: isMobile ? 5 : 10,
              cursor: "pointer"
            }}
            aria-label="partner logo button"
            onClick={navigateToHomePage}
          />
          {isUserLoggedIn && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography
                fontSize={isMobile ? 14 : 16}
                data-testid="user-email"
              >
                {userProfile.email}
              </Typography>
              <IconButton
                aria-label="logout-btn"
                disableRipple
                onClick={logoutUser}
                aria-description="Logout"
              >
                <LogoutIcon
                  style={{
                    height: isMobile ? 20 : 30,
                    width: isMobile ? 20 : 30,
                    marginLeft: `${isMobile ? 2 : 10}px`
                  }}
                />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;
