/* eslint-disable no-case-declarations */
import MultiselectInput from "./MultiselectInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import { getInitialConfigurationValue } from "../../lib/siteHelpers";
import ToggleSelect from "./ToggleSelect";
import CellInput from "./Cell";
import {
  checkValueTypes,
  parseSafeJSONConfiguration,
  safeStringifyConfigurationData
} from "../../lib/helpers";

export type ItemInputProps = {
  configurationValue: ConfigurationValueInputType | null;
  dataType: ConfigurationItemDataType;
  readOnly?: boolean;
  isDisabled?: boolean;
  required: boolean;
  validationRules: ValidationRulesType | null;
  onInputChange?: (value: ConfigurationValueInputType) => void;
  isPartOfPendingChangesList?: boolean;
  setValidationError?: (customValidationError: string) => void;
  isInNewSiteOperationMode?: boolean;
  inputWidth?: number | string;
  errorMessage?: string;
};

function ItemInput(props: ItemInputProps) {
  const { configurationValue, dataType, validationRules } = props;

  const formatJSONDataTypeItem = (
    inputValue: Record<string, unknown> | string
  ) => {
    const jsonDataToDisplay =
      Object.keys(inputValue).length > 0 && typeof inputValue !== "string"
        ? safeStringifyConfigurationData(inputValue, {
            replacer: null,
            space: 2
          })
        : "";

    return (
      <TextInput
        {...props}
        inputValue={
          typeof inputValue === "object" ? jsonDataToDisplay : inputValue
        }
      />
    );
  };
  const defaultConfigurationValue = getInitialConfigurationValue(
    dataType,
    validationRules
  );
  const inputValue = configurationValue || defaultConfigurationValue;

  const getDataTypeForItemInput = () => {
    switch (dataType) {
      case "boolean":
      case "bool":
        if (!checkValueTypes(inputValue, ["boolean", "string"])) return <></>;

        return (
          <ToggleSelect {...props} value={String(inputValue).toLowerCase()} />
        );

      case "text":
      case "guid":
      case "url":
        if (typeof inputValue !== "string") return <></>;

        return (
          <TextInput
            {...props}
            inputValue={String(inputValue)}
            textLengthLimit={validationRules?.textLengthLimit}
          />
        );
      case "jsonb":
      case "json":
        if (!["string", "object"].includes(typeof inputValue)) return <></>;

        return formatJSONDataTypeItem(
          inputValue as Record<string, unknown> | string
        );

      case "select":
      case "text[]":
      case "int[]":
      case "ordered_list":
        const selectLabels = validationRules?.allowedValues || [];
        if (typeof inputValue !== "string" || !selectLabels.length)
          return <></>;

        return (
          <SelectInput
            {...props}
            value={String(inputValue)}
            selectValues={selectLabels}
          />
        );

      case "int":
        if (!checkValueTypes(inputValue, ["number", "string"])) return <></>;
        return (
          <TextInput
            {...props}
            inputValue={inputValue as string | number}
            min={validationRules?.min}
            max={validationRules?.max}
          />
        );

      case "multiselect":
        const multiSelectLabels = validationRules?.allowedValues || [];
        if (!Array.isArray(inputValue) || !multiSelectLabels.length)
          return <></>;

        return (
          <MultiselectInput
            {...props}
            value={inputValue}
            multiselectValues={multiSelectLabels}
          />
        );
      case "cell[]":
        if (typeof configurationValue !== "string") return <></>;

        const formattedValue = parseSafeJSONConfiguration(
          configurationValue
        ) as CellCoord[] | null;

        if (!formattedValue || !Array.isArray(formattedValue)) return <></>;

        return <CellInput {...props} value={formattedValue} />;
    }
  };
  return getDataTypeForItemInput();
}

export default ItemInput;
