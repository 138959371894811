import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const getAllDrawerState = (state: RootState) =>
  state.mainCustomDrawer.drawerState;

export const getCurrentOpenDrawerType = (state: RootState) =>
  state.mainCustomDrawer.currentSelectedOpenDrawerType;

export const getSelectedDrawersData = (state: RootState) =>
  state.mainCustomDrawer.selectedDrawersData;

export const getAvailableDrawers = (state: RootState) => {
  const drawers = state.mainCustomDrawer.drawerState;
  const availableDrawersToDisplay = {} as DrawerState;

  for (const [drawerType, drawerOption] of Object.entries(drawers)) {
    if (drawerOption.shouldDisplay) {
      //@ts-ignore
      availableDrawersToDisplay[drawerType] = drawerOption;
    }
  }
  return availableDrawersToDisplay;
};
export const getCurrentDrawerStateProperties = (
  drawerType: DrawerType
): ((state: RootState) => {
  previousDrawer: DrawerType | null;
  nextDrawer: DrawerType | null;
  isOpen?: boolean;
  shouldDisplay?: boolean;
  isDisabled?: boolean;
}) =>
  createSelector([getAvailableDrawers], (drawerState: DrawerState) => {
    const availableDrawersToDisplayByType = Object.keys(
      drawerState
    ) as DrawerType[];
    const currentSelectedDrawerPosition =
      //@ts-ignore
      availableDrawersToDisplayByType.findIndex((el) => el === drawerType);

    const prevIndex = currentSelectedDrawerPosition - 1;
    const nextIndex = currentSelectedDrawerPosition + 1;

    const previousDrawer =
      (prevIndex >= 0 && availableDrawersToDisplayByType[prevIndex]) || null;

    const nextDrawer = ((nextIndex < availableDrawersToDisplayByType.length &&
      availableDrawersToDisplayByType[nextIndex]) ||
      null) as DrawerType | null;

    return {
      ...(drawerState[drawerType] as unknown as DrawerState),
      previousDrawer,
      nextDrawer
    };
  });

export const getRootDrawer: (state: RootState) => {
  drawerName: DrawerType;
  site?: DrawerStateOptions;
  partner?: DrawerStateOptions;
  client?: DrawerStateOptions;
} = createSelector(getAvailableDrawers, (drawers) => {
  const rootDrawerTypeName = Object.keys(drawers)[0] as DrawerType;
  return {
    drawerName: rootDrawerTypeName,
    ...(drawers as Record<string, DrawerStateOptions>)
  };
});
