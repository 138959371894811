import { useContext, useEffect } from "react";
import { ConfirmActionDialogContext } from "./components/useConfirmActionDialog";

const ResetContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const { resetDialogState } = useContext(ConfirmActionDialogContext);

  useEffect(() => {
    return () => {
      resetDialogState();
    };
  }, [resetDialogState]);

  return children;
};

export default ResetContextWrapper;
